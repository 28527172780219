import React from 'react';
import LoaderGif from '../../Assets/NearshopzLoader.gif';

const CenteredCircularProgress = () => {
  return (
    <div style={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      height: '100vh' // Full viewport height
    }}>
      <img src={LoaderGif} alt="Loading..." className="loader-image"/>
    </div>
  );
};

export default CenteredCircularProgress;
