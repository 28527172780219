import React from "react";
import ExploreButton from "../Buttons/ExploreButton";
import { FEATURES } from "./constants";
import "./index.css";

const Whyus = (props) => {
  return (
    <section className="features_section">
      <div className="features_top">
        <p className="heading-font">
          <b>
            {
              "Why Sell on NearShopz? Grow Your Business and Reach New Customers"
            }
          </b>
        </p>
        <p className="order">
          NearShopz is the all-in-one solution for local businesses to thrive
          online.
        </p>
      </div>
      <div className="features_container">
        {FEATURES.map((f) => (
          <div className="feature_item" key={f.heading}>
            <div className="featureIcon_div">
              <img
                src={f.icon}
                alt={f.alt}
                title={f.heading}
                className="featureIcon"
                loading="lazy"
                width="50"
                height="50"
              />
            </div>
            <div className="featureItem_info">
              <h2>
                <span className="heading-text1">
                  {f.heading ===
                  "24/7 Seller Support: Your Success is Our Priority" ? (
                    <>
                      <span className="responsive-text1">
                        24/7 Seller Support: Your&nbsp;
                      </span>
                      <span className="responsive-text1">
                      Success is Our&nbsp;
                      </span>
                      <span className="responsive-text1">
                         Priority
                      </span>
                    </>
                  ) : (
                    f.heading
                  )}
                </span>
              </h2>
              <p>{f.description}</p>
            </div>
          </div>
        ))}
      </div>
      <div className="become-a-seller-button-div">
        <ExploreButton screen="small" {...props} />
      </div>
    </section>
  );
};

export default Whyus;
