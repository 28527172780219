import React from "react";
import {
  Dialog,
  Drawer,
  IconButton,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import LoginForm from "./LoginForm";
import SignUpForm from "./SignUpForm";
import CloseIcon from "@mui/icons-material/Close";
import "./style.css";


const LoginOrSignupModal = (props) => {
  const [open, setOpen] = React.useState(false);
  const [login, setLogin] = React.useState(false);
  const [signup, setSignup] = React.useState(false);
  const fullScreen = useMediaQuery(useTheme().breakpoints.down("md"));
  const [state, setState] = React.useState({
    bottom: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };
  const ModalClick = () => {
    setLogin(false);
    setSignup(false);
    setState({ bottom: true });
  };
  const upClick = () => {
    setLogin(false);
    setSignup(false);
    document.querySelector(".home-login-form").style.marginLeft = "-50%";
    document.querySelector(".title-text .login").style.marginLeft = "-50%";
  };
  const inClick = () => {
    setLogin(false);
    setSignup(false);
    document.querySelector(".home-login-form").style.marginLeft = "0%";
    document.querySelector(".title-text .login").style.marginLeft = "0%";
  };
  const loginModalClick = () => {
    setLogin(true);
    setSignup(false);
    setOpen(true);
    setState({ bottom: false });
  };
  const signupModalClick = () => {
    setSignup(true);
    setLogin(false);
    setOpen(true);
    setState({ bottom: false });
    document.getElementsByClassName(".home-login-form").style.marginLeft =
      "-100%";
    document.getElementsByClassName(".title-text .login").style.marginLeft =
      "-50%";
  };
  const handleClose = () => {
    setOpen(false);
    window.scrollTo(0, 0);
  };
  React.useEffect(
    () => {
      if (props.isLogInSuccess === true) {
        localStorage.setItem("userid", props.Login.userid);
        localStorage.setItem("token", props.Login.token);
        props.navigate("/stores");
        props.getUserDetails(localStorage.getItem("userid"));
        setOpen(false);
      } else if (props.isSignUpSuccess === true) {
        localStorage.setItem("userid", props.Signup.userid);
        localStorage.setItem("token", props.Signup.token);
        props.navigate("/stores");
        props.getUserDetails(localStorage.getItem("userid"));
        setOpen(false);
      } else if (props.isVerifyOTP === true) {
        localStorage.setItem(
          "userid",
          props.VerifyOTP.userid && props.VerifyOTP.userid
        );
        localStorage.setItem(
          "token",
          props.VerifyOTP.token && props.VerifyOTP.token
        );
        props.navigate("/stores");
        props.getUserDetails(localStorage.getItem("userid"));
        setOpen(false);
      }
    },
    // eslint-disable-next-line
    [props.isLogInSuccess, props.isSignUpSuccess, props.isVerifyOTP]
  );

  return (
    <>
      {props.componentkey === "nologin" ? (
        <div className="nologin-form">
          <span className="loginorsignupbutton" onClick={loginModalClick}>
            Login Now
          </span>
          <span
            style={{
              paddingTop: "15px",
              paddingBottom: "15px",
            }}
          >
            &nbsp; or &nbsp;
          </span>
          <span className="loginorsignupbutton" onClick={signupModalClick}>
            Sign Up
          </span>
        </div>
      ) : props.componentkey === "checkout" ? (
        <div
          onClick={ModalClick}
          style={{
            background: "rgba(255, 255, 255, 0.5)",
            // opacity:0.5,
            borderRadius: "18px",
            fontWeight: 600,
            fontSize: "14px",
            lineHeight: "22px",
            color: "#061303",
            border: "none",
            cursor: "pointer",
            paddingLeft: "15px",
            paddingRight: "15px",
            paddingTop: "5px",
            paddingBottom: "5px",
          }}
        >
          {"Buy Now"}
        </div>
      ) : (
        <div className="link-form">
          {"Already a User?"}&nbsp;
          <span className="link-text" onClick={loginModalClick}>
            Login
          </span>
          &nbsp; or &nbsp;
          <span className="link-text" onClick={signupModalClick}>
            Sign-Up
          </span>
        </div>
      )}
      <Dialog
        scroll="body"
        disableBackdropClick={fullScreen ? true : false}
        disableEscapeKeyDown={fullScreen ? true : false}
        fullScreen={fullScreen}
        PaperProps={{
          sx: {
            borderRadius: { xs: 0, md: "10px" },
            background: "#fff",
            width: "100%",
            padding: "0px",
            margin: 0,
          },
        }}
        maxWidth={"md"}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <>
          <div className="wrapper" style={{ background: "#fff" }}>
            <div className="form-container">
              <span>
                <IconButton
                  aria-label="close"
                  onClick={() => {
                    setOpen(false);
                    window.scrollTo(0, 0);
                  }}
                  sx={{
                    position: "absolute",
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
              </span>
              {login === false && signup === false && (
                <div className="slide-controls">
                  <input
                    type="radio"
                    name="slide"
                    id="login"
                    onClick={inClick}
                    checked
                  />
                  <input
                    type="radio"
                    name="slide"
                    id="signup"
                    onClick={upClick}
                  />
                  <label for="login" className="slide login">
                    Login
                  </label>
                  <label for="signup" className="slide signup">
                    Register
                  </label>
                  <div className="slider-tab"></div>
                </div>
              )}
              <div className="form-inner">
                {login === true ? (
                  <div className="home-login-form">
                    <LoginForm {...props} />
                  </div>
                ) : signup === true ? (
                  <div className="signup-form">
                    <SignUpForm {...props} />
                  </div>
                ) : (
                  <div className="form-inner">
                    <div className="home-login-form">
                      <LoginForm {...props} />
                    </div>
                    <div className="signup-form">
                      <SignUpForm {...props} />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </>
      </Dialog>
      <Drawer
        anchor={"bottom"}
        open={state["bottom"]}
        PaperProps={{
          style: {
            background: "#fff",
            paddingTop: "25px",
            paddingBottom: "25px",
            borderTopLeftRadius: "14px",
            borderTopRightRadius: "14px",
          },
        }}
        onClose={toggleDrawer("bottom", false)}
        sx={{
          borderTopLeftRadius: "14px",
          borderTopRightRadius: "14px",
        }}
      >
        <div className="nologin-form">
          <span className="loginorsignupbutton" onClick={loginModalClick}>
            Login Now
          </span>
          <span
            style={{
              paddingTop: "15px",
              paddingBottom: "15px",
            }}
          >
            &nbsp; or &nbsp;
          </span>
          <span className="loginorsignupbutton" onClick={signupModalClick}>
            Sign Up
          </span>
        </div>
      </Drawer>
    </>
  );
};

export default LoginOrSignupModal;
