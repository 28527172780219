import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { termsandconditions } from "../TermsAndPrivacyPolicy/privacypolicy";
import Header from "../Header";
import "./index.css";
import "./styles.js";

const TermsAndConditions = (props) => {
  React.useEffect(() => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  }, []);
  return (
    <React.Fragment>
      <Header {...props} />
      <Grid
        container
        justifyContent={"center"}
        alignItems={"center"}
        textAlign={"center"}
      >
        <Grid item xs={11}>
          <h1 className="mainHeading">
            {/* {MAIN_HEADING} */}
            Terms and Conditions
          </h1>
        </Grid>
        <Grid item xs={10}>
        <Typography
              paragraph
              className="description"
              sx={{
                fontSize: {
                  xs: "13px",
                  sm: "16px",
                  md: "20px",
                },
              }}
            >
            Thank you for visiting our website, NearShopz.in ("Website") or
            downloading our mobile application ("App"). While the NearShopz
            partners finish your personal tasks, please spare the time to read
            the following terms and conditions. You can learn about our services
            from the Website and use our services through the mobile application
            or App (the Website and the App referred to herein as "NearShopz
            Platform.)
          </Typography>
        </Grid>
        <Grid item xs={10}>
          {/* {termsandconditions.map((terms,index)=>
                    <div className='terms' key={index}>
                        <div className='termsHeading'>
                            <h2 className='termsTitle'>
                                {`${terms.index}.\t${terms.heading}`}
                            </h2>
                        </div>
                        <div className='description'>
                            {terms.description}
                        </div>
                    </div>
                )} */}
          <Box mb={4} sx={{ marginTop: "20px" }}>
            <Typography
              variant="h5"
              gutterBottom
              //fontFamily="Roboto"
              sx={{
                paddingBottom: "0px",
                fontSize: {
                  xs: "18px", // For screens smaller than 600px
                  sm: "20px", // For screens larger than 600px and smaller than 1024px
                  md: "22px", // Default font size for screens larger than 1024px
                },
              }}
              className="termsTitle"
            >
              1. Acceptance of Terms
            </Typography>
            {/* <Typography variant="h6" gutterBottom>1.1</Typography> */}
            <Typography
              paragraph
              className="description"
              sx={{
                fontSize: {
                  xs: "13px",
                  sm: "16px",
                  md: "20px",
                },
              }}
            >
              1.1. By accessing or utilizing the NearShopz platform, inclusive
              of its website and mobile applications, you hereby agree to adhere
              to the following Terms and Conditions. Should you disagree with
              these terms, kindly refrain from using our services.
            </Typography>

            <Typography
              paragraph
              className="description"
              sx={{
                fontSize: {
                  xs: "13px",
                  sm: "16px",
                  md: "20px",
                },
              }}
            >
              1.2. These Terms and Conditions establish a legally binding
              agreement between yourself ("User," "you," "your") and NearShopz
              (“NearShopz”), operated by 4Labs Technologies Pvt, Ltd. By
              utilizing our platform, you assert that you are of legal age to
              enter such a contract.
            </Typography>

            <Typography
              variant="h5"
              gutterBottom
              sx={{
                paddingBottom: "0px",
                fontSize: {
                  xs: "18px", // For screens smaller than 600px
                  sm: "20px", // For screens larger than 600px and smaller than 1024px
                  md: "22px", // Default font size for screens larger than 1024px
                },
              }}
              className="termsTitle"
            >
              2. Services
            </Typography>

            <Typography
              paragraph
              className="description"
              sx={{
                fontSize: {
                  xs: "13px",
                  sm: "16px",
                  md: "20px",
                },
              }}
            >
              2.1. NearShopz provides a hyperlocal delivery platform
              facilitating connections between users and delivery providers. The
              range of services offered may encompass order placement, payment
              processing, and delivery of goods.
            </Typography>

            <Typography
              paragraph
              className="description"
              sx={{
                fontSize: {
                  xs: "13px",
                  sm: "16px",
                  md: "20px",
                },
              }}
            >
              2.2. NearShopz reserves the prerogative to amend, suspend, or
              discontinue services, either wholly or partially, without prior
              notice. We shall not be held liable for any resultant losses or
              inconveniences.
            </Typography>

            <Typography
              variant="h5"
              gutterBottom
              sx={{
                paddingBottom: "0px",
                fontSize: {
                  xs: "18px", // For screens smaller than 600px
                  sm: "20px", // For screens larger than 600px and smaller than 1024px
                  md: "22px", // Default font size for screens larger than 1024px
                },
              }}
              className="termsTitle"
            >
              3. User Accounts
            </Typography>

            <Typography
              paragraph
              className="description"
              sx={{
                fontSize: {
                  xs: "13px",
                  sm: "16px",
                  md: "20px",
                },
              }}
            >
              3.1. To access specific features and services on NearShopz, users
              may be mandated to create a user account. It is the user's
              responsibility to maintain the confidentiality of their account
              information.
            </Typography>

            <Typography
              paragraph
              className="description"
              sx={{
                fontSize: {
                  xs: "13px",
                  sm: "16px",
                  md: "20px",
                },
              }}
            >
              3.2. Users are obliged to furnish accurate and current information
              during the registration process. NearShopz bears no responsibility
              for inaccuracies in the information provided.
            </Typography>

            <Typography
              variant="h5"
              gutterBottom
              sx={{
                paddingBottom: "0px",
                fontSize: {
                  xs: "18px", // For screens smaller than 600px
                  sm: "20px", // For screens larger than 600px and smaller than 1024px
                  md: "22px", // Default font size for screens larger than 1024px
                },
              }}
              className="termsTitle"
            >
              4. User Conduct
            </Typography>

            <Typography
              paragraph
              className="description"
              sx={{
                fontSize: {
                  xs: "13px",
                  sm: "16px",
                  md: "20px",
                },
              }}
            >
              4.1. Users undertake to utilize the NearShopz platform and
              services in accordance with all pertinent laws and regulations.
            </Typography>

            <Typography
              paragraph
              className="description"
              sx={{
                fontSize: {
                  xs: "13px",
                  sm: "16px",
                  md: "20px",
                },
              }}
            >
              4.2. Users must refrain from conduct deemed offensive, harmful, or
              infringing on others' rights, including harassment, fraud, and
              unauthorized use of intellectual property.
            </Typography>

            <Typography
              paragraph
              className="description"
              sx={{
                fontSize: {
                  xs: "13px",
                  sm: "16px",
                  md: "20px",
                },
              }}
            >
              4.3. Inappropriate behavior and fraudulent activities may result
              in the suspension or termination of user accounts.
            </Typography>

            <Typography
              variant="h5"
              gutterBottom
              sx={{
                paddingBottom: "0px",
                fontSize: {
                  xs: "18px", // For screens smaller than 600px
                  sm: "20px", // For screens larger than 600px and smaller than 1024px
                  md: "22px", // Default font size for screens larger than 1024px
                },
              }}
              className="termsTitle"
            >
              5. Orders and Deliveries
            </Typography>

            <Typography
              paragraph
              className="description"
              sx={{
                fontSize: {
                  xs: "13px",
                  sm: "16px",
                  md: "20px",
                },
              }}
            >
              5.1. Users can place orders through the NearShopz platform for
              goods and services offered by merchants.
            </Typography>

            <Typography
              paragraph
              className="description"
              sx={{
                fontSize: {
                  xs: "13px",
                  sm: "16px",
                  md: "20px",
                },
              }}
            >
              5.2. By placing an order, users affirm their commitment to provide
              accurate delivery information, comprising the delivery address and
              contact particulars.
            </Typography>

            <Typography
              paragraph
              className="description"
              sx={{
                fontSize: {
                  xs: "13px",
                  sm: "16px",
                  md: "20px",
                },
              }}
            >
              5.3. Users acknowledge that delivery times stipulated on the
              platform are approximate and actual delivery times may deviate
              based on various factors such as traffic and weather conditions.
            </Typography>

            <Typography
              paragraph
              className="description"
              sx={{
                fontSize: {
                  xs: "13px",
                  sm: "16px",
                  md: "20px",
                },
              }}
            >
              5.4. NearShopz and its delivery partners will exert reasonable
              efforts to fulfill orders within the estimated delivery time.
              However, NearShopz shall not be held accountable for delays beyond
              its control.
            </Typography>

            <Typography
              paragraph
              className="description"
              sx={{
                fontSize: {
                  xs: "13px",
                  sm: "16px",
                  md: "20px",
                },
              }}
            >
              5.5. In instances of non-delivery attributable to user-related
              factors (e.g., incorrect address provided), users may incur
              additional fees for redelivery, or the order may be canceled, at
              NearShopz's discretion.
            </Typography>

            <Typography
              variant="h5"
              gutterBottom
              sx={{
                paddingBottom: "0px",
                fontSize: {
                  xs: "18px", // For screens smaller than 600px
                  sm: "20px", // For screens larger than 600px and smaller than 1024px
                  md: "22px", // Default font size for screens larger than 1024px
                },
              }}
              className="termsTitle"
            >
              6. Payments
            </Typography>

            <Typography
              paragraph
              className="description"
              sx={{
                fontSize: {
                  xs: "13px",
                  sm: "16px",
                  md: "20px",
                },
              }}
            >
              6.1. In case of payment disputes, encompassing unauthorized
              transactions, billing errors, or charge discrepancies, users are
              encouraged to promptly contact NearShopz customer support for
              resolution.
            </Typography>

            <Typography
              paragraph
              className="description"
              sx={{
                fontSize: {
                  xs: "13px",
                  sm: "16px",
                  md: "20px",
                },
              }}
            >
              6.2. NearShopz will promptly investigate payment disputes upon
              receipt of a complaint from the user. Users may be required to
              furnish relevant information and documentation to facilitate the
              investigation.
            </Typography>

            <Typography
              paragraph
              className="description"
              sx={{
                fontSize: {
                  xs: "13px",
                  sm: "16px",
                  md: "20px",
                },
              }}
            >
              6.3. If a payment dispute is substantiated, NearShopz will take
              appropriate measures to rectify the error, which may involve
              issuing refunds, adjusting charges, or providing compensation, as
              deemed necessary.
            </Typography>

            <Typography
              paragraph
              className="description"
              sx={{
                fontSize: {
                  xs: "13px",
                  sm: "16px",
                  md: "20px",
                },
              }}
            >
              6.4. Users are obligated to cooperate with NearShopz during the
              investigation of payment disputes and to provide accurate
              information to expedite resolution.
            </Typography>

            <Typography
              paragraph
              className="description"
              sx={{
                fontSize: {
                  xs: "13px",
                  sm: "16px",
                  md: "20px",
                },
              }}
            >
              6.5. NearShopz reserves the right to suspend or terminate user
              accounts or limit access to the platform in cases of suspected
              fraudulent activity or misuse of the payment system.
            </Typography>

            <Typography
              paragraph
              className="description"
              sx={{
                fontSize: {
                  xs: "13px",
                  sm: "16px",
                  md: "20px",
                },
              }}
            >
              6.6. Users acknowledge that NearShopz may be subject to the
              policies and procedures of third-party payment processors, and
              disputes related to payments processed through such entities may
              be governed by their terms and conditions.
            </Typography>

            <Typography
              paragraph
              className="description"
              sx={{
                fontSize: {
                  xs: "13px",
                  sm: "16px",
                  md: "20px",
                },
              }}
            >
              6.7. Users are accountable for payments associated with orders and
              deliveries. Payment processing is facilitated by NearShopz, and
              users agree to furnish accurate payment information.
            </Typography>

            <Typography
              variant="h5"
              gutterBottom
              sx={{
                paddingBottom: "0px",
                fontSize: {
                  xs: "18px", // For screens smaller than 600px
                  sm: "20px", // For screens larger than 600px and smaller than 1024px
                  md: "22px", // Default font size for screens larger than 1024px
                },
              }}
              className="termsTitle"
            >
              7. Payment Terms
            </Typography>

            <Typography
              paragraph
              className="description"
              sx={{
                fontSize: {
                  xs: "13px",
                  sm: "16px",
                  md: "20px",
                },
              }}
            >
              7.1. Users agree to remunerate the purchase price for goods and
              services ordered through the platform, alongside any applicable
              taxes and delivery charges.
            </Typography>

            <Typography
              paragraph
              className="description"
              sx={{
                fontSize: {
                  xs: "13px",
                  sm: "16px",
                  md: "20px",
                },
              }}
            >
              7.2. Payments for orders may be executed through accepted payment
              methods, encompassing credit/debit cards, net banking, digital
              wallets, or cash on delivery (where available).
            </Typography>

            <Typography
              paragraph
              className="description"
              sx={{
                fontSize: {
                  xs: "13px",
                  sm: "16px",
                  md: "20px",
                },
              }}
            >
              7.3. Users recognize that NearShopz may engage third-party payment
              processors to facilitate transactions. By effecting a payment,
              users assent to abide by the terms and conditions of such
              third-party processors.
            </Typography>

            <Typography
              paragraph
              className="description"
              sx={{
                fontSize: {
                  xs: "13px",
                  sm: "16px",
                  md: "20px",
                },
              }}
            >
              7.4. All payments are definitive and non-refundable, except as
              stipulated in our refund policy.
            </Typography>

            <Typography
              paragraph
              className="description"
              sx={{
                fontSize: {
                  xs: "13px",
                  sm: "16px",
                  md: "20px",
                },
              }}
            >
              7.5. In instances of cash on delivery, users must tender the exact
              amount due upon delivery. NearShopz and its delivery partners
              reserve the right to withhold delivery if the exact amount is not
              provided or if payment concerns arise.
            </Typography>

            <Typography
              variant="h5"
              sx={{
                paddingBottom: "0px",
                fontSize: {
                  xs: "18px", // For screens smaller than 600px
                  sm: "20px", // For screens larger than 600px and smaller than 1024px
                  md: "22px", // Default font size for screens larger than 1024px
                },
              }}
              gutterBottom
              className="termsTitle"
            >
              8. Intellectual Property
            </Typography>

            <Typography
              paragraph
              className="description"
              sx={{
                fontSize: {
                  xs: "13px",
                  sm: "16px",
                  md: "20px",
                },
              }}
            >
              8.1. The NearShopz website, mobile applications, and affiliated
              content are safeguarded by intellectual property rights, including
              copyrights and trademarks.
            </Typography>

            <Typography
              paragraph
              className="description"
              sx={{
                fontSize: {
                  xs: "13px",
                  sm: "16px",
                  md: "20px",
                },
              }}
            >
              8.2. Users are prohibited from reproducing, distributing, or
              utilizing NearShopz's intellectual property without explicit
              permission.
            </Typography>

            <Typography
              variant="h5"
              gutterBottom
              sx={{
                paddingBottom: "0px",
                fontSize: {
                  xs: "18px", // For screens smaller than 600px
                  sm: "20px", // For screens larger than 600px and smaller than 1024px
                  md: "22px", // Default font size for screens larger than 1024px
                },
              }}
              className="termsTitle"
            >
              9. Privacy
            </Typography>

            <Typography
              paragraph
              className="description"
              sx={{
                fontSize: {
                  xs: "13px",
                  sm: "16px",
                  md: "20px",
                },
              }}
            >
              9.1. User utilization of the NearShopz platform is subject to our
              Privacy Policy, delineating the collection, usage, and
              safeguarding of personal information.
            </Typography>

            <Typography
              variant="h5"
              gutterBottom
              sx={{
                paddingBottom: "0px",
                fontSize: {
                  xs: "18px", // For screens smaller than 600px
                  sm: "20px", // For screens larger than 600px and smaller than 1024px
                  md: "22px", // Default font size for screens larger than 1024px
                },
              }}
              className="termsTitle"
            >
              10.Liability and Disclaimers
            </Typography>

            <Typography
              paragraph
              className="description"
              sx={{
                fontSize: {
                  xs: "13px",
                  sm: "16px",
                  md: "20px",
                },
              }}
            >
              10.1. NearShopz furnishes its services on an "as is" and "as
              available" basis. We do not warrant that our services will be
              devoid of errors, secure, or uninterrupted.
            </Typography>
            {/* <Typography variant="h6" gutterBottom>10.2</Typography> */}
            <Typography
              paragraph
              className="description"
              sx={{
                fontSize: {
                  xs: "13px",
                  sm: "16px",
                  md: "20px",
                },
              }}
            >
              10.2. NearShopz bears no responsibility for the quality or safety
              of goods or services delivered by third-party providers.
            </Typography>

            <Typography
              variant="h5"
              gutterBottom
              sx={{
                paddingBottom: "0px",
                fontSize: {
                  xs: "18px", // For screens smaller than 600px
                  sm: "20px", // For screens larger than 600px and smaller than 1024px
                  md: "22px", // Default font size for screens larger than 1024px
                },
              }}
              className="termsTitle"
            >
              11. Termination
            </Typography>

            <Typography
              paragraph
              className="description"
              sx={{
                fontSize: {
                  xs: "13px",
                  sm: "16px",
                  md: "20px",
                },
              }}
            >
              11.1. NearShopz reserves the right to terminate or suspend user
              accounts for violations of these Terms and Conditions.
            </Typography>

            <Typography
              variant="h5"
              gutterBottom
              sx={{
                paddingBottom: "0px",
                fontSize: {
                  xs: "18px", // For screens smaller than 600px
                  sm: "20px", // For screens larger than 600px and smaller than 1024px
                  md: "22px", // Default font size for screens larger than 1024px
                },
              }}
              className="termsTitle"
            >
              12. Changes to Terms and Conditions
            </Typography>
            {/* <Typography variant="h6" gutterBottom>12.1</Typography> */}
            <Typography
              paragraph
              className="description"
              sx={{
                fontSize: {
                  xs: "13px",
                  sm: "16px",
                  md: "20px",
                },
              }}
            >
              12.1. NearShopz may revise these Terms and Conditions to reflect
              alterations in our practices. Users will be notified of
              significant revisions.
            </Typography>

            <Typography
              variant="h5"
              gutterBottom
              sx={{
                paddingBottom: "0px",
                fontSize: {
                  xs: "18px", // For screens smaller than 600px
                  sm: "20px", // For screens larger than 600px and smaller than 1024px
                  md: "22px", // Default font size for screens larger than 1024px
                },
              }}
              className="termsTitle"
            >
              13. Governing Law and Jurisdiction
            </Typography>
            {/* <Typography variant="h6" gutterBottom>13.1</Typography> */}
            <Typography
              paragraph
              className="description"
              sx={{
                fontSize: {
                  xs: "13px",
                  sm: "16px",
                  md: "20px",
                },
              }}
            >
              13.1. These Terms and Conditions are governed by and construed in
              accordance with the laws of India. Any disputes arising under
              these terms shall fall within the exclusive jurisdiction of the
              courts of India.
            </Typography>
            <Typography
              variant="h5"
              gutterBottom
              sx={{
                paddingBottom: "0px",
                fontSize: {
                  xs: "18px", // For screens smaller than 600px
                  sm: "20px", // For screens larger than 600px and smaller than 1024px
                  md: "22px", // Default font size for screens larger than 1024px
                },
              }}
              className="termsTitle"
            >
              Refund and Cancellation Policy
            </Typography>
            <Typography
              paragraph
              className="description"
              sx={{
                fontSize: {
                  xs: "13px",
                  sm: "16px",
                  md: "20px",
                },
              }}
            >
              1. Introduction
            </Typography>
            <Typography
              paragraph
              className="description"
              sx={{
                fontSize: {
                  xs: "13px",
                  sm: "16px",
                  md: "20px",
                },
              }}
            >
              1.1. This Refund and Cancellation Policy ("Policy") delineates the
              terms and conditions governing refund requests and order
              cancellations on NearShopz, ("NearShopz"), a hyperlocal delivery
              platform operated by 4Labs Technologies Pvt. Ltd.
            </Typography>
            <Typography
              paragraph
              className="description"
              sx={{
                fontSize: {
                  xs: "13px",
                  sm: "16px",
                  md: "20px",
                },
              }}
            >
              1.2. By utilizing our platform and services, you acknowledge that
              you have perused, comprehended, and consent to adhere to this
              Policy. If you dissent from this Policy, kindly abstain from
              utilizing our services.
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default TermsAndConditions;
