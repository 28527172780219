import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    flexDirection: "column",
    color: "#fff",
    fontWeight: 400,
    fontSize: "12px",
    lineHeight: "16px",
    textAlign: "center",
    justifyContent: "center",
    alignItems: "center",
    border: "none",
    backgroundColor: "transparent",
    letterSpacing: "1px",
    cursor: "pointer",
  },
  notokendiv: {
    height: "88vh",
    width: "100%",
    padding: "15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  loginpopupdiv: {
    width: "100%",
    height: "100%",
    background: "#fff",
    borderRadius: "13px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  accountavatar: {
    width: "100px",
    height: "100px",
  },
  accounticon: {
    width: "22px",
    height: "20px",
  },
  floatLeft: {
    textAlign: "left",
    color: "#000000",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "22px",
    lineHeight: "16px",
    cursor: "pointer",
    float: "left",
    display: "flex",
    alignItems: "center",
    width: "100%",
    "@media (max-width: 768px)": {
      fontSize: "20px",
    },
  },
  floatRight: {
    textAlign: "right",
    color: "#EA4335",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "12px",
    lineHeight: "16px",
    cursor: "pointer",
    float: "right",
  },
  logout: {
    textAlign: "center",
    color: "#EA4335",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "12px",
    lineHeight: "16px",
    cursor: "pointer",
  },
  userBox: {
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  userName: {
    fontStyle: " normal",
    fontWeight: "bold",
    fontSize: "18px",
    lineHeight: "25px",
    textAlign: "center",
    color: "#061303",
    paddingTop: "13px",
  },
  userEmail: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px",
    lineHeight: "18px",
    textAlign: "center",
    color: "#061303",
    opacity: 0.5,
    paddingBottom: "40px",
    paddingTop: "15px",
  },
  accountlistBox: {
    background: " #FFFFFF",
    boxShadow: "0px 8px 44px rgba(0, 0, 0, 0.04)",
    borderRadius: "24px",
    width: "100%",
    height: "auto",
    // padding:'40px'
  },
  list: {
    display: "flex",
    flexDirection: "column",
  },
  title: {
    color: "#061303",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "19px",
  },
  logouttitle: {
    color: "#EA4335",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "19px",
  },
  leftarrow: {
    background: "#EAF6E1",
    width: "30px",
    // height:'30px',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    borderRadius: "50%",
  },
  subtitle: {
    color: "#061303",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "13px",
    lineHeight: "18px",
    opacity: 0.5,
  },
  button: {
    background: "#85BE49",
    color: "#FFFFFF",
    fontSize: "16px",
    lineHeight: "19px",
    fontWeight: 600,
    textAlign: "center",
    borderRadius: "12px",
    padding: "10px",
    border: `1px #85BE49`,
    width: "50%",
    cursor: "pointer",
    marginLeft: "15px",
    "@media(max-width: 426px)": {
      fontWeight: 500,
    },
  },
  buttonCancel: {
    background: "#ffffff",
    color: "#85BE49",
    fontSize: "16px",
    lineHeight: "19px",
    fontWeight: 600,
    textAlign: "center",
    borderRadius: "12px",
    border: `1px solid #85BE49`,
    padding: "10px",
    width: "50%",
    cursor: "pointer",
    "@media(max-width: 426px)": {
      fontWeight: 500,
    },
  },
}));
