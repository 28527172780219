import React from 'react';
import { useStyles } from "./style.js";
import errorproduct from '../../Assets/Images/errorproduct.svg';
import emptycart from '../../Assets/Images/emptycart.svg';
import { db } from '../db.js';
import { useLiveQuery } from 'dexie-react-hooks';
import CloseIcon from '@mui/icons-material/Close';
import { Tooltip } from '@mui/material';
import './index.css';

const StoreCart = (props) => {

    const classes = useStyles();
    const { cart } = db;
    const cartdata = useLiveQuery(() => cart.toArray(), []);
    const calCulateStoretotal = (data) => {
        let t = 0;
        data && data !== null && data[0] && data[0].products &&
            data[0].products.map(p =>
                t = t + (Number(p.cartquantity) * Number(p.productprice))
            )
        return parseFloat(t).toFixed(2);
    };
    
    return (
        <div
            style={{
                background: '#EAF6E1',
                paddingTop: '15px',
                paddingBottom: '15px',
                width: '450px',
                paddingLeft: '15px',
                paddingRight: '15px',
                borderRadius: '13px',
                height: `calc(${100}vh-${70}px)`,
                position: 'sticky',
            }}>
            <div
                style={{
                    background: '#ffffff',
                    borderRadius: '13px',
                    width: "100%",
                }}>
                <div className={classes.cartStoreHeading}>
                    <span className={classes.cartStoreName}>
                        <span className={classes.storename}>
                            <b>
                                {`Items from ${localStorage.getItem('storeName') && localStorage.getItem('storeName')}`}
                            </b>
                        </span>
                        <span className={classes.clearStorediv}>
                            <span className={classes.storetotal}>
                                {cartdata && cartdata !== null &&
                                    cartdata.length > 0 ?
                                    cartdata.filter(({ storeid }) => storeid === localStorage.getItem('storeid')).length > 0 ?
                                        `${cartdata.filter(({ storeid }) => storeid === localStorage.getItem('storeid'))[0].products.length} Items` :
                                        `0 Item`
                                    : `0 Item`}
                            </span>
                            &nbsp;
                        </span>
                    </span>
                    {/* <span
                        className={classes.cartStoreTotal}
                        style={{
                            fontWeight: 700,
                            background: 'rgba(255, 217, 81, 0.7)',
                            borderRadius: '15.5px',
                            color: 'rgba(0,0,0,0.7)'
                        }}> */}
                        <span
                    className={classes.cartStoreTotal}
                    style={{
                      fontWeight: 800,
                      background: "rgba(255, 217, 81, 0.7)",
                      borderRadius: "15.5px",
                      color: "rgba(0,0,0,0.9)",
                    }}
                  >
                        <b>
                             {"₹ " + parseFloat(
                                calCulateStoretotal(cartdata && cartdata !== null &&
                                    cartdata.length > 0 &&
                                    cartdata.filter(({ storeid }) => storeid === localStorage.getItem('storeid')))).toFixed(2)}
                        </b>
                    </span>
                </div>
                <div className={classes.root}>
                    {cartdata && cartdata !== null &&
                        cartdata.length > 0 ?
                        cartdata.filter(({ storeid }) => storeid === localStorage.getItem('storeid')).length > 0 ?
                            cartdata.filter(({ storeid }) => storeid === localStorage.getItem('storeid')).map((cart) =>
                                <div style={{ background: 'transparent', width: '100%' }}>
                                    {cart.products && cart.products.map((product) =>
                                        <div style={{ paddingBottom: '15px', background: '#EAF6E1' }}>
                                            <div className={classes.cartProductBox} >
                                                <div className={classes.cartProductDetails}>
                                                    <span style={{ float: 'left', cursor: 'pointer' }}
                                                        onClick={() => {
                                                            localStorage.setItem('productid', product.productid);
                                                            localStorage.setItem('storeid', cart.storeid && cart.storeid);
                                                            localStorage.setItem('productstoreid', cart.storeid && cart.storeid);
                                                            props.history.push(`/stores/product/${product.productid}`)
                                                        }}>
                                                        {((
                                                            product.image === null ||
                                                            product.image === 'null' ||
                                                            product.image === undefined ||
                                                            product.image === 'undefined'
                                                        )) ?
                                                            <img src={errorproduct} className={classes.cartImage} alt="product" />
                                                            :
                                                            <img src={product.image && product.image} className={classes.cartImage} alt="product" />
                                                        }
                                                    </span>
                                                    <span className={classes.productDetailsDiv}>
                                                        <span className={classes.productName}>
                                                            <Tooltip title={product.productname && product.productname}>
                                                                <span
                                                                    style={{
                                                                        width: '100%',
                                                                        textOverflow: 'ellipsis',
                                                                        wordBreak: 'break-word',
                                                                        lineHeight: '1.5em',
                                                                        height: '3em',
                                                                        display: '-webkit-box',
                                                                        overflow: 'hidden',
                                                                        WebkitLineClamp: 2,
                                                                        webkitBoxOrient: 'vertical',
                                                                    }}>
                                                                    <b>
                                                                        {product.productname && product.productname}
                                                                    </b>
                                                                </span>
                                                            </Tooltip>
                                                        </span>
                                                        <span className={classes.productprice}>
                                                            <span
                                                                style={{
                                                                    float: 'left',
                                                                    width: '100%',
                                                                    display: 'flex'
                                                                }}>
                                                                <b>
                                                                    <span className={classes.cartPrice}>
                                                                        ₹ 
                                                                        {product?.productprice && parseFloat(
                                                                            product?.productprice
                                                                        ).toFixed(2)
                                                                        }
                                                                    </span>
                                                                    {' /'}&nbsp;{product.productQuantity && product.productQuantity}{product.uom && product.uom}
                                                                </b>
                                                            </span>
                                                            <span
                                                                style={{
                                                                    // fontSize:'18px',
                                                                    float: 'right',
                                                                    textAlign: 'right',
                                                                    // width:'100%',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'right'
                                                                }}>
                                                                <CloseIcon fontSize='small' />&nbsp;
                                                                <span className={classes.cartquantity}>
                                                                    {product.cartquantity && product.cartquantity}
                                                                </span>
                                                            </span>
                                                        </span>

                                                    </span>

                                                </div>
                                            </div>
                                            {/* <Divider /> */}
                                        </div>
                                    )}
                                </div>
                            )
                            : (
                                cartdata.filter(({ storeid }) => storeid === localStorage.getItem('storeid')).length === 0 &&
                                <div className={classes.noordersDiv}>
                                    <img src={emptycart} alt="noorders" className={classes.noordersimage} />
                                    <p className={classes.noorderstext}>
                                        {`You haven’t added anything from this store `}
                                    </p>
                                </div>)
                        : (<div className={classes.noordersDiv}>
                            <img src={emptycart} alt="noorders" className={classes.noordersimage} />
                            <p className={classes.noorderstext}>
                                {`You haven’t added anything from this store `}
                            </p>
                        </div>)
                    }
                </div>
            </div>
        </div>
    )
}

export default StoreCart
