
/* eslint-disable no-unused-vars */
import React from "react";
import Card from "../Card";
import { useState, useEffect } from "react";
import NewAddressPopUp from "./AddNewaddress";
import AddressListingSection from "./AddresssList";
import Grid from "@mui/material/Grid";

import { PLACEORDER_ADDRESS } from "../../../Redux/Actions/Queries";
import client from "../../../Configurations/apollo";
import "./index.css";

const ChooseAddressSection = (props) => {
  
  const [addressopen, setAddressOpen] = React.useState(false);
  const [availableAddresses, setAvailableAddresses] = useState(props?.Cart?.availableAddress);


  useEffect(() => {
    
    if(((props?.Cart?.availableAddress === null )||(props?.Cart?.availableAddress?.length===0) )&&( props?.AddAddressClicked === true)){
      
      window.location.reload()
    }
    if(props?.AddAddressClicked === true){
      window.location.reload()
    }
    setAvailableAddresses(props?.Cart?.availableAddress);
  }, 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [props?.isAddaddress, props?.AddAddressClicked]);

  React.useEffect(
    () => {
      if (!props?.placeorderaddressid || props?.placeorderaddressid === null) {
        if (
          props?.AvailableAddresses &&
          props?.AvailableAddresses !== null &&
          props?.AvailableAddresses?.length > 0
        ) {
          props?.selectCheckoutAddress(
            props?.AvailableAddresses[0].id,
            props?.AvailableAddresses[0]?.houseNumber,
            props?.AvailableAddresses[0]?.locationDescription,
            props?.AvailableAddresses[0]?.locationLat,
            props?.AvailableAddresses[0]?.locationLng
          );
          client
            .query({
              query: PLACEORDER_ADDRESS,
              variables: {
                userid: localStorage.getItem("userid"),
                lat: props?.AvailableAddresses[0]?.locationLat,
                lng: props?.AvailableAddresses[0]?.locationLng,
              },
            })
            .then((res) => {
              if (
                res?.data &&
                res.data?.placeOrderNewAddressCheck &&
                res.data?.placeOrderNewAddressCheck.length === 0
              ) {
                console.log(
                  "this is respone from  PLACEORDER_ADDRESS",
                  res?.data
                );
              } else if (
                res.data &&
                res.data.placeOrderNewAddressCheck &&
                res.data.placeOrderNewAddressCheck.length > 0
              ) {
                setAddressOpen(false);
                props?.selectCheckoutAddress(null, null, null, null, null);
              } else if (res.errors) {
                console.log(res.errors[0].message);
              }
            })
            .catch((err) => {
              return null;
            });
        }
      }
    },
    // eslint-disable-next-line
    [props?.isAvailableAddresses, props?.AvailableAddresses]
  );



  let matchingItem = props?.Cart?.availableAddress?.find(
    (item) => item._id === props?.Cart?.addressid
  );

  let time = matchingItem
    ? Number(matchingItem?.distance?.duration?.text.split(" ")[0])
    : 0;


  return (
    <>
      <Card>
        <Grid container style={{ alignItems: "center", maxWidth: "100%" }}>
          <Grid
            item
            xs={0.5}
            sm={0.5}
            md={0.5}
            style={{ display: "flex", alignItems: "center" }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="20"
              viewBox="0 0 14 20"
              fill="none"
            >
              <path
                d="M7 9.5C6.33696 9.5 5.70107 9.23661 5.23223 8.76777C4.76339 8.29893 4.5 7.66304 4.5 7C4.5 6.33696 4.76339 5.70107 5.23223 5.23223C5.70107 4.76339 6.33696 4.5 7 4.5C7.66304 4.5 8.29893 4.76339 8.76777 5.23223C9.23661 5.70107 9.5 6.33696 9.5 7C9.5 7.3283 9.43534 7.65339 9.3097 7.95671C9.18406 8.26002 8.99991 8.53562 8.76777 8.76777C8.53562 8.99991 8.26002 9.18406 7.95671 9.3097C7.65339 9.43534 7.3283 9.5 7 9.5ZM7 0C5.14348 0 3.36301 0.737498 2.05025 2.05025C0.737498 3.36301 0 5.14348 0 7C0 12.25 7 20 7 20C7 20 14 12.25 14 7C14 5.14348 13.2625 3.36301 11.9497 2.05025C10.637 0.737498 8.85652 0 7 0Z"
                fill="black"
              />
            </svg>
          </Grid>
          <Grid
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "12px",
            }}
            item
            xs={9.5}
            sm={9.5}
            md={9.5}
          >
            <h2 style={{ fontSize: "18px", fontWeight: 700, color: "#000" }}>
              Choose Address
            </h2>
          </Grid>
          <Grid
            item
            xs={2}
            md={2}
            lg={2}
            sm={2}
            style={{
              display: "flex",
              alignItems: "center",
              textAlign: "right",
              justifyContent: "flex-end",
            }}
          >
            <NewAddressPopUp {...props} screen={"checkout"} />
            {/* <Addaddress {...props}/> */}
          </Grid>
        </Grid>
        <Grid
          container
          style={{ display: "flex", alignItems: "center", paddingTop: "15px" }}
        >
          {props?.Cart?.availableAddress?.length === 0 ? (
            <div
              style={{
                width: "100%",
                textAlign: "center",
                fontWeight: 700,
                fontSize: "18px",
              }}
            >
              No address is available; add a new address.
            </div>
          ) : (
            <AddressListingSection
              // addresses={addresslist}
              {...props}
            />
          )}
        </Grid>
        {props?.Cart?.availableAddress?.length !== 0&&
        
        <Grid
          container
          style={{
            display: "flex",
            alignItems: "center",
            paddingTop: "15px",
          }}
        >
          <span
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              textAlign: "center",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
            >
              <path
                d="M7.5 1.25C10.9519 1.25 13.75 4.04813 13.75 7.5C13.75 10.9519 10.9519 13.75 7.5 13.75C4.04813 13.75 1.25 10.9519 1.25 7.5C1.25 4.04813 4.04813 1.25 7.5 1.25ZM7.5 3.75C7.33424 3.75 7.17527 3.81585 7.05806 3.93306C6.94085 4.05027 6.875 4.20924 6.875 4.375V7.5C6.87504 7.66575 6.94091 7.82469 7.05813 7.94187L8.93313 9.81687C9.051 9.93072 9.20888 9.99372 9.37275 9.9923C9.53662 9.99087 9.69338 9.92514 9.80926 9.80926C9.92514 9.69338 9.99087 9.53662 9.9923 9.37275C9.99372 9.20888 9.93072 9.051 9.81687 8.93313L8.125 7.24125V4.375C8.125 4.20924 8.05915 4.05027 7.94194 3.93306C7.82473 3.81585 7.66576 3.75 7.5 3.75Z"
                fill="#6FAD59"
              />
            </svg>
          </span>
          <span
            style={{
              display: "flex",
              alignItems: "center",
              paddingLeft: "7px",
              color: "#000",
              fontSize: "14px",
              fontWeight: 600,
            }}
          >
            {`${props?.Cart?.preparationTime + time} min`}
          </span>
        </Grid>
}
      </Card>
    </>
  );
};
export default ChooseAddressSection;
