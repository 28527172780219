// RecentSearches.js
import React from "react";
import { useEffect } from "react";

const RecentSearches = ({ props, onRecenetItemClick }) => {
  
  useEffect(() => {
    props.getUserDetails(localStorage.getItem("userid"));
  }, []);
  console.log("recent", props?.Account?.recentSearches)
  return (
    <>
   { props?.Account?.recentSearches && (
    <div className="recent_serach_parent">
      
      <p className="recent-search-head">Recent Searches</p>
    
      <div className="recent_serach_flex">
        {props?.Account?.recentSearches?.map((recentItem, index) => {
          return (
            <div
              className="recent_serach_child_box"
              key={index}
              onClick={() => handleClick(recentItem)}
            >
              {recentItem}
            </div>
          );
        })}
      </div>
    </div>
  )}
   </> 
  );
  function handleClick(recentItem) {
    onRecenetItemClick(recentItem);
    props?.updateRecentSearch(recentItem);
  }
};

export default RecentSearches;
