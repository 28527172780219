import React, { useRef, useState } from "react";
import Card from "../Card";
import "./PaymentDetails.css";
import { Grid, SvgIcon } from "@mui/material";
import { ReactComponent as exclamationicon } from "../../../Assets/Icons/exclamationimg.svg";
import Divider from "@mui/material/Divider";
//import DeliveryTip from "../DeliveryTip/DeliveryTip";
import DeliveryTipsSection from '../DeliveryTip/DeliveryTip';
import ApplyCouponDrawer from "../Coupon/ApplyCouponDrawer";
import ItemDetails from "./ItemDetails";

const PaymentDetails = (props) => {
  const deliveryTipsRef = useRef(null);
  const [isOpened, setOpen] = useState(false);
  const [platformFee,setPlatFormFee] = useState(props.Cart?.platformFee)
  const scrollToDeliveryTips = () => {
    setOpen(true);
    if (deliveryTipsRef.current) {
      deliveryTipsRef.current.focus();
    }
  };

  console.log("platform",props.Cart)
  
  return (
    <Card hasBorder={true} borderPosition={"bottom"}>
      <Grid container>
        <ItemDetails
          // products={props?.Cart?.products}
          {...props}
        />
      </Grid>
      <div className="line1">
        <Divider
          sx={{
            borderBottom: "2px",
            borderStyle: "dashed",
            color: "darkgrey",
          }}
        />
      </div>
      <Grid container sx={{ paddingTop: "40px", paddingBottom: "40px" }}>
        <Grid item md={5.5} xs={12}>
          <ApplyCouponDrawer {...props} />
        </Grid>
        <Grid item md={0.5} xs={12} />
        <Grid
          item
          md={5.5}
          xs={12}
          alignItems={"flex-end"}
          sx={{ width: "100%" }}
        >
            <DeliveryTipsSection ref={deliveryTipsRef} isOpened={isOpened} setOpen={setOpen} props={props} />
        </Grid>
      </Grid>
      <div className="payment-details-container">
        <div className="paymentdetails">
          <h2 className="underline-text">Payment Details</h2>
        </div>

        <div className="line1">
          <Divider
            sx={{
              borderBottom: "2px",
              borderStyle: "dashed",
              color: "darkgrey",
            }}
          />
        </div>

        <div
          style={{
            width: "100%",
            display: "flex",
            flexDirection: "column",
            gap: "12px",
          }}
        >
          <div className="payment-row1 flex">
            <div>
              <p className="payment-label1">Item Total</p>
            </div>
            <div className="alignment">
              <p className="payment-value1">
                ₹ {parseFloat(props?.Cart?.totalPrice).toFixed(2)}
              </p>
            </div>
          </div>

          <div className="payment-row flex">
            <div
              style={{
                display: "flex",
              }}
            >
              <p className="payment-label2"> Partner Delivery Fee</p>
              <div>
                {/* <SvgIcon
                  style={{ height: "22px",marginLeft:"5px" }}
                <SvgIcon
                  style={{ height: "22px",marginLeft:"5px",marginTop:"7px" }}
                  component={exclamationicon}
                /> */}
              </div>
            </div>

            <div className="alignment">
              <span className="payment-value2">
                ₹ {parseFloat(props?.Cart?.deliveryCharge).toFixed(2)}
              </span>
            </div>
          </div>
          <div className="payment-row flex">
            <div
              style={{
                display: "flex",
              }}
            >
              <p className="payment-label2"> GST</p>
              <div>
                {/* <SvgIcon
                  style={{ height: "22px",marginLeft:"5px" }}
                <SvgIcon
                  style={{ height: "22px",marginLeft:"5px",marginTop:"7px" }}
                  component={exclamationicon}
                /> */}
              </div>
            </div>

            <div className="alignment">
              <span className="payment-value2">
                ₹ {parseFloat(props?.Cart?.GST).toFixed(2)}
              </span>
            </div>
          </div>
          <div className="payment-row flex">
            <div
              style={{
                display: "flex",
              }}
            >
              <p className="payment-label2"> Platform Fee</p>
              <div>
                {/* <SvgIcon
                  style={{ height: "22px",marginLeft:"5px" }}
                <SvgIcon
                  style={{ height: "22px",marginLeft:"5px",marginTop:"7px" }}
                  component={exclamationicon}
                /> */}
              </div>
            </div>

            <div className="alignment">
              <span className="payment-value2">
                ₹ {parseFloat(props.Cart?.platformFee).toFixed(2)}
              </span>
            </div>
          </div>
          <div className="payment-row3 flex">
            <div className="payment-label3">
              <div
                style={{
                  display: "flex",
                }}
              >
                <p>Packing & Handling Charges</p>
             
              </div>
            </div>

            <div className="alignment">
              <p className="payment-value3">
                ₹ {"  "}
                {parseFloat(
                  props?.Cart?.packingCharge + props?.Cart?.handlingCharge
                ).toFixed(2)}
              </p>
            </div>
          </div>

          <div className="payment-row4 flex">
            <div>
              <p className="payment-label4">Tips For Delivery Partner</p>
            </div>
            <div className="alignment">
              <p className="payment-value4">
                {props?.Cart?.deliveryTip?
              <div>₹ {parseFloat(props?.Cart?.deliveryTip).toFixed(2)}</div>:<div onClick={scrollToDeliveryTips} style={{cursor:"pointer",color:"blue"}}>Add</div>}
              </p>
            </div>
          </div>
        
          <div className="line2">
            <Divider
              sx={{
                borderBottom: "2px",
                borderStyle: "dashed",
                color: "darkgrey",
              }}
            />
          </div>


          {props?.Cart?.couponIsApplied === true &&
            props?.Cart?.discountPrice ? (
              <div>
                <div
                  className="payment-section flex wd-100"
                  style={{
                    padding: "6px 0 6px 0",
                  }}
                >
                  <div>
                    <p className="paymentlabeltotal">Total</p>
                  </div>
                  <div className="alignment">
                    <p className="payment-valuetotal-value">
                      ₹ {parseFloat(props?.Cart?.total).toFixed(2)}
                    </p>
                  </div>
                </div>
                <div
                  className="payment-section flex wd-100"
                  style={{
                    padding: "6px 0 6px 0",
                  }}
                >
                  <div>
                    <p className="payment-label6">{`Coupon Discount-(${props?.Cart?.couponCode})`}</p>
                  </div>
                  <div className="alignment">
                    <p className="payment-value6">
                      -₹ {parseFloat(props?.Cart?.discountPrice).toFixed(2)}
                    </p>
                  </div>
                </div>
                <div
                  className="payment-section flex wd-100"
                  style={{
                    padding: "6px 0 6px 0",
                  }}
                >
                  <div>
                    <p className="paymentlabeltotallabel">Total Payable</p>
                  </div>
                  <div className="alignment">
                    <p className="paymentvaluetotal">
                      ₹ {parseFloat(props?.Cart?.totalPayable).toFixed(2)}
                    </p>
                  </div>
                </div>
              </ div>
            ):
          (<div className=" wd-100"  >
              
              < div className=" payment-section flex  wd-100">
                <div className="   wd-100"  >
                  <p className="paymentlabeltotallabel">Total Payable</p>
                </div>
                <div className="alignment">
                  <span style={{marginRight:"4px"}}>{"₹"}</span>
                  <span className="payment-valuetotal-value">
                     {parseFloat(props?.Cart?.totalPayable).toFixed(2)}
                  </span>
                </div>
              </div>
            
          </div>
          )}
        </div>
      </div>
    </Card>
  );
};

export default PaymentDetails;
