import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import contactIcon from "../../Assets/Images/contacticon.svg";
import WhatsAppIcon from "../../Assets/Images/whatsAppIcon.svg";


const Help = () => {

  return (
    <React.Fragment>
      <div
        className="main-support"
      >
        <List
          sx={{ width: "100%", maxWidth: 400, bgcolor: "background.paper" }}
        >
          <ListItem>
            <ListItemAvatar>
              <Avatar
                sx={{
                  bgcolor: "#ffffff",
                  border: "1px solid #D1D8E0",
                  color: "#85BE49",
                }}
              >
                <img
                  src={contactIcon}
                  alt="icon"
                  width={"18.75rem"}
                  height={"18.75rem"}
                />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="Contact Us : 7736209990"
              secondary="Working hours from 09:00 AM to 09:00 PM"
            />
          </ListItem>
          <ListItem>
            <ListItemAvatar>
              <Avatar
                sx={{
                  bgcolor: "#ffffff",
                  border: "1px solid #D1D8E0",
                  color: "#85BE49",
                }}
              >
                <img
                  src={WhatsAppIcon}
                  alt="icon"
                  width={"18.75rem"}
                  height={"18.75rem"}
                />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary="WhatsApp : 7736209990"
              secondary="Chat with us on WhatsApp, 24 x 7 Support"
            />
          </ListItem>
        </List>
      </div>
    </React.Fragment>
  );
};

export default Help;
