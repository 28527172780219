import React, { useState } from 'react';
import { Grid, SvgIcon, Radio, RadioGroup, FormControlLabel } from '@mui/material';
import { PAYMENT_TYPE_HEADING } from './Policytext';
import Card from './Card';
import { ReactComponent as PaymentOption } from '../../Assets/Icons/PaymentOption.svg';
import { makeStyles } from '@mui/styles';

const PaymentType = (props) => {

    const useStyles = makeStyles(() => ({
        radioContainer: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "0.5rem 1rem",
            borderRadius: "8px",
            marginBottom: "2rem",
            border: "1px solid #ccc",
            width: "100%",
        },
        selectedRadio: {
            color: "#89C74A !important", // Custom color for selected radio button
        },
        selectedBackground: {
            backgroundColor: "rgba(105, 168, 92, 0.15)" // Background color for selected item
        },
        defaultBackground: {
            backgroundColor: "#fff", // Background color for non-selected item
        },
        root: {
            color: '#000',
            fontFeatureSettings: 'clig off, liga off',
            fontSize: '1rem',
            fontWeight: 600,
            lineHeight: '1.375rem',
            paddingLeft: "22px",
            wordBreak: "break-word"
        },
        noteDiv: {
            paddingTop: '1rem',
            fontSize: '0.75rem',
            fontWeight: 400
        },
        radioLabel: {
            display: 'flex',
            justifyContent: 'space-between', // Ensures the label and radio are spaced apart
            width: '100%',
            flexDirection: 'row-reverse', // Reverses the radio button and label positions
            alignItems: 'center',
        },
        labelText: {
            paddingLeft: '16px', // Add padding to the left of the label
        },
    }));

    const classes = useStyles();

    const [selectedValue, setSelectedValue] = useState(`${props?.paymentType?.paymentType}`);

    const handleChange = (event) => {
        setSelectedValue(event.target.value);
        props.setPaymentType(event.target.value); // Pass the selected payment type to parent component
    };

    return (
        <Card hasBorder={true} borderPosition={"bottom"}>
            <Grid container>
                <Grid item xs={12}>
                    <Grid container alignItems="center">
                        <Grid item xs={0.25}>
                            <SvgIcon component={PaymentOption} inheritViewBox />
                        </Grid>
                        <Grid item xs={11.75} className={classes.root}>
                            {PAYMENT_TYPE_HEADING}
                        </Grid>
                    </Grid>
                </Grid>

                <Grid item xs={12} className={classes.noteDiv} />

                {/* RadioGroup for Payment Type */}
                <RadioGroup
                    name="paymentType"
                    value={selectedValue}
                    onChange={handleChange} // Handle state change
                    style={{ width: '100%' }} // Full width for the RadioGroup
                >
                    {/* Online Payment Option */}
                    <Grid
                        container
                        className={`${classes.radioContainer} ${selectedValue === "online" ? classes.selectedBackground : classes.defaultBackground}`}
                        alignItems="center"
                    >
                        <Grid item xs={12}>
                            <FormControlLabel
                                value="online"
                                control={<Radio classes={{ checked: classes.selectedRadio }} />}
                                label={<div className={classes.labelText}>Online</div>} // Label with padding
                                className={classes.radioLabel}
                            />
                        </Grid>
                    </Grid>

                    {/* Cash on Delivery Payment Option */}
                    <Grid
                        container
                        className={`${classes.radioContainer} ${selectedValue === "cod" ? classes.selectedBackground : classes.defaultBackground}`}
                        alignItems="center"
                    >
                        <Grid item xs={12}>
                            <FormControlLabel
                                value="cod"
                                control={<Radio classes={{ checked: classes.selectedRadio }} />}
                                label={<div className={classes.labelText}>Cash On Delivery</div>} 
                                className={classes.radioLabel}
                            />
                        </Grid>
                    </Grid>
                </RadioGroup>
            </Grid>
        </Card>
    );
};

export default PaymentType;
