import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import OTPInput from "otp-input-react";
import { useFormik } from "formik";
import * as Yup from "yup";
import TextField from '@mui/material/TextField';
import CloseIcon from '@mui/icons-material/Close';
import { useStyles } from "./style.js";
import AlertBar from '../../AlertBar/index.js';
import { InputAdornment } from '@mui/material';
import indiaImage from '../../../Assets/Images/indiaImage.svg';
const AddPhoneNumber = (props) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [state, setState] = React.useState({ mobileNumber: "", otp: "" });
    const [counter, setCounter] = React.useState(59);
    React.useEffect(() => {
        const timer =
            counter > 0 && setInterval(() => setCounter(counter - 1), 1000);
        return () => clearInterval(timer);
    }, [counter]);

    const handleOTPChange = (element) => {
        setState({ ...state, otp: element });
    };

    const validationSchema = Yup.object().shape({
        phoneNumber: Yup.string()
            .matches(/^[0-9]*$/, "Please enter only numbers")
            .min(10, "Please enter 10 digit Mobile number")
            .max(10, "Please enter 10 digit Mobile number")
            .required("Please enter Mobile number"),
    });

    const sendOTP = (ph) => {
        props?.props?.props?.sendotp(ph, localStorage.getItem("userid"));
        setOpen(true);
    };
    const verifyotp = (ph, otp) => {
        props?.props?.props?.verifyotp(ph, otp, localStorage.getItem("userid"));
    };
    const formik = useFormik({
        initialValues: {
            phoneNumber: "",
        },
        validationSchema: validationSchema,
        onSubmit: (values) => {
            setState({ open: true });
            sendOTP(formik.values.phoneNumber);
        },
    });

    const handleClickOpen = () => {
        formik.setFieldValue('phoneNumber','');
        formik.setFieldTouched('phoneNumber',false);
        formik.setFieldError('phoneNumber','');
        setCounter(59);
        props.props.props.otpclose();
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };
    return (
        <>
            <div
                className={classes.addphonenumber} onClick={handleClickOpen}>
                {'No Phone Number is set. Tap to Add '}
            </div>
            <Dialog
                PaperProps={{
                    style: {
                        borderRadius: '10px',
                        backgroundColor: '#ffffff',
                        width: '100%',
                        // padding: '40px'
                    }
                }}
                fullScreen={fullScreen}
                maxWidth={'sm'}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle className={classes.modalHeading}>
                    <div className={classes.modaltitle}
                        style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                        <span style={{
                            width: '95%',
                            fontWeight: 600, display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center'
                        }}>
                            {props?.props?.props?.isOTP && props?.props?.props?.sendOTP ? (
                                "Mobile Number Verification"
                            ) :
                                "Add Phone Number"}
                        </span>
                        <span
                            style={{
                                float: 'right', color: 'rgba(0, 0, 0, 0.8)',
                                width: '5%',
                                display: 'flex', justifyContent: 'right', alignItems: 'center', textAlign: 'right',
                            }}>
                            <span
                                style={{
                                    width: '25px',
                                    color: '#ffffff',
                                    borderRadius: '50%',
                                    fontSize: '14px',
                                    height: '25px', background: '#BDBDBD', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center'
                                }}>
                                <CloseIcon
                                    style={{
                                        cursor: 'pointer',
                                        fontSize: '14px'
                                    }}
                                    onClick={handleClose} />
                            </span>
                        </span>
                    </div>
                </DialogTitle>

                <DialogContent dividers style={{ width: '100%',justifyContent:'center',alignItems:'center',display:'flex',flexDirection:'column' }}>

                    <form onSubmit={formik.handleSubmit} style={{ width: "100%",justifyContent:'center',alignItems:'center',display:'flex',flexDirection:'column'}}>
                        {props.props.props.isOTP && props.props.props.sendOTP ?
                            props.props.props.VerifyOTP === true && props.props.props.verifyOTPsuccess ? (
                                <>
                                "Your phone number verified successfully !"
                                <div style={{width:'100%',paddingTop:'25px',justifyContent:'center',display:'flex',alignItems:'center'}}>
                                <button className='solid-button'  onClick={(e) => {
                                    e.persist();
                                    e.preventDefault();
                                    props.props.props.addPlaceorderPhoneNumber(
                                        formik.values.phoneNumber
                                    );
                                    handleClose();
                                }}>
                                                    {'OK'}
                                                </button>
                                </div>
                                </>
                            ) : (
                                // props.props.props.isOTP === true && props.props.props.sendOTP === true &&
                                <React.Fragment>
                                    <div style={{justifyContent:'center',
                                    alignItems:'center',
                                    display:'flex',
                                    flexDirection:'column',
                                    textAlign:'center',
                                    width:'100%',
                                    color:'#69A85C',
                                    fontSize:'14px',
                                    lineHeight:'18px',
                                    fontWeight:400 }}>
                                    <p >
                                        {`An OTP has been sent to your number ending with ${formik.values.phoneNumber && formik.values.phoneNumber.slice(-4)}.Please enter the code to continue`}
                                    </p>
                                    </div>
                                    <div style={{
                                        width: '100%', paddingTop: '25px', paddingBottom: '15px', justifyContent: 'center', alignItems: 'center', display: 'flex',
                                        flexDirection: 'column'
                                    }}>
                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <OTPInput name="otp"
                                                autoFocus
                                                inputStyles={{ border: '1px solid rgba(0,0,0,0.5)' }}
                                                value={state.otp}
                                                onChange={handleOTPChange} OTPLength={4} otpType="number" disabled={false} />
                                        </div>
                                        {  props?.props?.props?.isVerifyOTPFail===true&&props?.props?.props?.verifyOTPFailed&&
                                <div style={{justifyContent:'center',
                                    alignItems:'center',
                                    display:'flex',
                                    flexDirection:'column',
                                    textAlign:'center',
                                    width:'100%',
                                    color:'#EA4335',
                                    fontSize:'18px',
                                    lineHeight:'18px',
                                    paddingTop:'10px',
                                    fontWeight:400 }}>
                                    <p >
                                        {`Invalid OTP`}
                                    </p>
                                    </div> }
                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', paddingTop: '25px' }}>
                                            <span style={{ width: '100%', float: 'left' }}>
                                                {counter > 0 ?
                                                    <span> 00:{counter}</span>
                                                    :
                                                    <button className='outlined-button' type="button"
                                                        onClick={() => { sendOTP(formik.values.phoneNumber); setCounter(59) }}>
                                                        {'Resend OTP'}
                                                    </button>
                                                }
                                            </span>
                                            <span style={{ width: '100%', float: 'right', paddingLeft: '15px',paddingRight:0,justifyContent:'right',display:'flex' }}>
                                                <button 
                                                 type="button"
                                                 className={(state.otp&&state.otp!==""&&state.otp.length>=4)?
                                                    'solid-button'
                                                    :
                                                    classes.disablebutton}
                                                disabled={
                                                (    (state.otp&&state.otp!==""&&state.otp.length>=4)
                                    
                                        
                                    )?
                                                    false:true}
                                                 onClick={() => verifyotp(formik.values.phoneNumber, state.otp)}>
                                                    {'Verify'}
                                                </button>
                                            </span>
                                          
                                        </div>
                                        {/* </form> */}
                                    </div>
                                </React.Fragment>
                            )

                            :
                            <div style={{paddingTop:'15px',width:'100%'}}>
                            <TextField
                                fullWidth
                                InputProps={{
                                    style: {
                                        width: '100%', borderRadius: '12px', fontSize: '16px',
                                        height: '56px', background: '#fff'
                                    }, autoComplete: 'off',
                                    startAdornment: (
                                        <InputAdornment position="start">
                                          <img
                                            src={indiaImage}
                                            alt="India flag"
                                            style={{ marginRight: "8px" }}
                                          />
                                          <div style={{ color: "black" }}>+91 |</div>
                                        </InputAdornment>
                                      ),
                                }}
                                InputLabelProps={{
                                    shrink: true,
                                    
                                }}
                                name="phoneNumber"
                                value={formik.values.phoneNumber}
                                onChange={formik.handleChange}
                                error={
                                    formik.touched.phoneNumber &&
                                    Boolean((formik.errors.phoneNumber))
                                }
                                helperText={
                                    formik.touched.phoneNumber && formik.errors.phoneNumber
                                }
                                label="Enter your phone number"
                                variant="outlined" />
                                <div style={{width:'100%',paddingTop:'25px',justifyContent:'center',display:'flex',alignItems:'center'}}>
                                <button className='solid-button' type='submit'>
                                                    {'Send OTP'}
                                                </button>
                                </div>
                           
                                </div>
                        }
                    </form>
                </DialogContent>

            </Dialog>
         
            {props?.props?.props.isSendOTPFailed === true && props.props.props.errorMessage&&
        <AlertBar type={'error'} message={props.props.props.errorMessage} {...props.props.props} props={props.props.props} />
      }
        </>
    )
}

export default AddPhoneNumber