import React from 'react';
import { Box, Grid } from '@mui/material';
import ProductCard from '../../Components/ProductCard';

const DepartmentProducts = (props) => {
    
    React.useEffect(() => {
        props.props.getDepartmentProducts({
            storeid: localStorage.getItem('storeid'),
            departmentid: localStorage.getItem('departmentid')
        });
    }
        // eslint-disable-next-line
        , []);
    return (
        <Box flex={4} sx={{ paddingTop: '25px',paddingBottom:"30px" }}>
            <Grid container spacing={2} sx={{ flexGrow: 1 }}>
                {
                    props.props.isStoreDetailsLoading && props.props.isStoreDetailsLoading === true ?
                        'Loading' :
                        (props.props.isStoreDetailsSuccess && props.props.isStoreDetailsSuccess === true &&
                            props.props.StoreDetails &&
                            props.props.StoreDetails !== null &&
                            props.props.StoreDetails.storeStatus === "active" && props.props.StoreDetails.storeWorking === true) ?
                            (props.props.isDepartmentProductsLoading && props.props.isDepartmentProductsLoading === true ?
                                'Loading' :
                                props.props.isDepartmentsProducts && props.props.isDepartmentsProducts === true &&
                                    props.props.DepartmentProducts && props.props.DepartmentProducts !== null && props.props.DepartmentProducts.items &&
                                    props.props.DepartmentProducts.items.length > 0 ?
                                    props.props.DepartmentProducts.items.map((item) =>
                                        <Grid item style={{ paddingBottom: '25px' }}>
                                            <ProductCard
                                                id={item.id}
                                                productname={item.productname && item.productname}
                                                image={item.image && item.image.primary && item.image.primary}
                                                price={item?.price && item?.price}
                                                markedupProductPrice={item?.markedupProductPrice&&item?.markedupProductPrice}
                                                strikeOffPrice={item?.strikeOffPrice&&item?.strikeOffPrice}
                                                promoprice={item.promoprice && item.promoprice}
                                                storeid={localStorage.getItem('storeid')}
                                                storename={localStorage.getItem('storeName')}
                                                uom={item.uom && item.uom}
                                                quantity={item.quantity}
                                                bxgy={item.bxgy}
                                                storeoffline={false}
                                                {...props} />
                                        </Grid>
                                    )
                                    : props.props.isDepartmentProductsFailed && props.props.isDepartmentProductsFailed === true &&
                                    'OOPS')
                            :
                            (
                                props.props.isDepartmentProductsLoading && props.props.isDepartmentProductsLoading === true ?
                                    'Loading' :
                                    props.props.isDepartmentsProducts && props.props.isDepartmentsProducts === true &&
                                        props.props.DepartmentProducts && props.props.DepartmentProducts !== null && props.props.DepartmentProducts.items &&
                                        props.props.DepartmentProducts.items.length > 0 ?
                                        props.props.DepartmentProducts.items.map((item) =>
                                            <Grid item style={{ paddingBottom: '25px' }}>
                                                <ProductCard
                                                    id={item.id}
                                                    productname={item.productname && item.productname}
                                                    image={item.image && item.image.primary && item.image.primary}
                                                    price={item.price && item.price}
                                                    markedupProductPrice={item?.markedupProductPrice&&item?.markedupProductPrice}
                                                    strikeOffPrice={item?.strikeOffPrice&&item?.strikeOffPrice}
                                                    promoprice={item.promoprice && item.promoprice}
                                                    storeid={localStorage.getItem('storeid')}
                                                    storename={localStorage.getItem('storeName')}
                                                    uom={item.uom && item.uom}
                                                    quantity={item.quantity}
                                                    bxgy={item.bxgy}
                                                    storeoffline={true}
                                                    {...props} />
                                            </Grid>
                                        )
                                        : props.props.isDepartmentProductsFailed && props.props.isDepartmentProductsFailed === true &&
                                        'OOPS'
                            )
                }
            </Grid>
        </Box>
    )
}

export default DepartmentProducts