import React from 'react';
import { useStyles } from "./style.js";
import { CircularProgress, Grid, useMediaQuery,useTheme } from '@mui/material';
import Addaddress from './Addaddress.js';
import EditAddress from './EditAddress.js';
import Deleteaddress from './Deleteaddress.js';
import noaddress from '../../Assets/Images/noaddress.svg';
import Container from '@mui/material/Container';
// import { useTheme } from '@emotion/react';

const Addresses = (props) => {
    const classes = useStyles();
    React.useEffect(() => {
        props.getAddress(localStorage.getItem('userid'))
    },
    // eslint-disable-next-line
        [])
        const theme = useTheme()
        const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
    return (
        <React.Fragment>
            <Container maxWidth="md">
                <div className={classes.headingdiv}>   
                    {'Manage Address'}
                </div>
                <div className='padding-div' />
                <div className={classes.root}>
                    <Grid container spacing={3} style={{ paddingBottom: fullScreen ?"110px" :"0px"}}>
                        <Grid item xs={12}>
                            {props.isAddresssesSuccess && props.isAddresssesSuccess === true &&
                                props.Addresses && props.Addresses !== null && props.Addresses.length <= 3 &&
                                <Addaddress props={props} />
                            }
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                {props.isAddressLoading && props.isAddressLoading === true ?
                                    'Loading'
                                  
                                     :
                                    props.isAddresssesSuccess && props.isAddresssesSuccess === true &&
                                        props.Addresses && props.Addresses !== null &&
                                        props.Addresses.length > 0 ?
                                        (props.Addresses.map((a) =>
                                            <Grid item xs={12} md={6} >
                                                <div className={classes.addresscard}>
                                                    <div className={classes.addressdiv}>
                                                        <span className={classes.addresstitle}>
                                                            {a.houseNumber}
                                                        </span>
                                                        <span className={classes.landmark01}>
                                                            {a.landmark && a.landmark !== null ? a.landmark : ''}
                                                        </span>
                                                        <span className={classes.houseLocation}>
                                                            {a.locationDescription}
                                                        </span>
                                                    </div>
                                                    <div className={classes.updationdiv}>
                                                        <span style={{ paddingRight: '10px' }}>
                                                            <EditAddress
                                                                id={a.id}
                                                                locationDescription={a.locationDescription}
                                                                locationLat={a.locationLat}
                                                                locationLng={a.locationLng}
                                                                Landmark={a.landmark}
                                                                houseNumber={a.houseNumber}
                                                                type={a.type}
                                                                {...props} />
                                                        </span>
                                                        <span>
                                                            <Deleteaddress
                                                                key={a.locationDescription}
                                                                id={a.id}
                                                                locationlattitude={a.locationLat}
                                                                locationlongitude={a.locationLng}
                                                                {...props} />
                                                        </span>
                                                    </div>
                                                </div>
                                            </Grid>
                                        )) : (
                                            <div className={classes.noordersDiv}>
                                                <img src={noaddress} alt="noorders" className={classes.noordersimage} />
                                                <p className={classes.noordersheading}>
                                                    {`You haven't set any address`}
                                                </p>
                                                <p className={classes.noorderstext}>
                                                    {`Please set an address for delivery to continue the purchase`}
                                                </p>
                                            </div>
                                        )}
                            </Grid>
                        </Grid>
                    </Grid>
                </div>
            </Container>
        </React.Fragment>
    )
}

export default Addresses