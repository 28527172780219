import React from 'react';
import { useStyles } from "./style.js";
import Label from './StatusLabel';
import { Checkbox, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, FormGroup, TextField, useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material';
import ReactStars from "react-rating-stars-component";
import { withStyles } from "@mui/styles";
import './index.css';

const GreenCheckbox = withStyles({
  root: {
    color: '#66BB6A',
    "&$checked": {
      color: '#43A047',
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);



const OrderCard = ({ id,
  type,
  orderrating,
  storelogo,
  Status,
  text,
  storename,
  ordernumber,
  product,
  total,
  totalprice,
  delivery,
  finalBillAmount,
  totalPayable,
    total2,
    handlingCharge,
    packingCharge,
    GST,
    deliveryTip,
    version,
  props }) => {
  const classes = useStyles();
  const them = useTheme();
  const fullScreen = useMediaQuery(them.breakpoints.down("sm"));
  const [feedbackopen, setFeedbackopen] = React.useState(false);
  const [reviewopen, setReviewopen] = React.useState(false);
  const [starrating, setRating] = React.useState('');
  const [areastoimprove, setAreastoimprove] = React.useState([]);
  const Title = ({ children }) => (
    <div className={classes.reviewheading}>{children}</div>
  );
  const [state, setState] = React.useState({
    selectedorderid: '',
    right: false,
    delivery: false,
    packaging: false,
    quality: false,
    quantity: false,
    behaviour: false,
    rating: "",
    orderid: "",
    comments: "",
    selected: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    if (open === true) {
      window.scrollTo({
        top: 0,
        behavior: "smooth"
      });
    }
    setState({ ...state, [anchor]: open });
  };
  const handlecommentChange = (e) => {
    setState({ ...state, comments: e.target.value });
  };

  const handleChange = (event) => {
    setState({ ...state, [event.target.name]: event.target.checked });
    setAreastoimprove([...areastoimprove, event.target.value]);
  };

  const handleFeedbackClose = () => {
    setFeedbackopen(false);
  };
  const handleReviewClose = () => {
    setReviewopen(false);
  };
  const ratingChanged = (id, newRating) => {
  
    if (newRating === 5) {
      props.addrating(id, newRating, "", "");
      setFeedbackopen(true);
    } else if (newRating < 5) {
      setState({ ...state, rating: newRating, orderid: id });
      setRating(newRating);
      setReviewopen(true);
    }
  };

  const rating = (state) => {
    props.addrating(
      state.orderid,
      starrating,
      areastoimprove,
      state.comments
    );
    setState({
      delivery: false,
      packaging: false,
      quality: false,
      quantity: false,
      behaviour: false,
      rating: "",
      orderid: "",
      comments: "",
    });
    setAreastoimprove([]);
    handleReviewClose();
  };

  const [messages, setMessages] = React.useState(null);
  const [liveorderdata, setLiveorderdata] = React.useState(0);
  const [status, setStatus] = React.useState(0);

  React.useEffect(() => {
      const messageListener = (message) => {
        if(message!==null)
        {  setMessages((prevMessages) => {
              const newMessages = { ...prevMessages };
              newMessages[message.id] = message;
              setStatus(message);
              setLiveorderdata({
                orderNumber: message.orderNumber,
                lastStatus: message.lastStatus,
                notifications: message.notifications,
                created: message.createdAt
              })
              return newMessages;
          });}
          else return null;
      };

      const deleteMessageListener = (messageID) => {
          setMessages((prevMessages) => {
              const newMessages = { ...prevMessages };
              delete newMessages[messageID];
              return newMessages;
          });
      };

      if (props.socket !== null) {
          props.socket.on(`${id}_order`, messageListener);
          props.socket.on('deleteMessage', deleteMessageListener);
          props.socket.emit('getMessages');
      }

      return () => {
          if (props.socket !== null) {
              props.socket.off(`${id}_order`, messageListener);
              props.socket.off('deleteMessage', deleteMessageListener);
          }
      };
  }, [props.socket]);

  return (
    <div key={props.orderid} className={(props.OrderDetails && props.OrderDetails !== null && props.OrderDetails.orderNumber === ordernumber) ? classes.selectedcard : classes.root}
      onClick={() => { setState({ ...state, selected: true, orderid: id, selectedorderid: ordernumber }) }}>
      <div className={classes.heading}>
        <span className={classes.orderHeading}>ORDER#:</span>
        <span className={classes.orderNumber}>{`\t${ordernumber}`}</span>
        <span className={classes.vieworderButton} onClick={toggleDrawer('right', true)}>{'VIEW ORDER'}</span>
      </div>
      <div className={classes.OrderItems}>
        <span>
          <img src={storelogo} alt="storelogo" className={classes.storeLogo} />
        </span>
        <span className={classes.storeDetails}>
          <span className={classes.storeName}>
            {storename}
          </span>
          <span className={classes.products}>
            {product}{product > 1 ? ' items' : ' item'}
          </span>
        </span>
      </div>
      <div className={classes.divider}></div>
      <div className={classes.orderDetails}>
    
        {version >= 2?
         <div>
          <span className={classes.totalHeading}>
            <b>{'Total Payable'}</b>
          </span>
          <span className={classes.total}>
            <b> {`${'₹' + totalPayable}`}</b>
          </span>
          <br />
        </div> 
        :
        <div>
          <span className={classes.totalHeading}>
            <b>{(delivery && delivery !== null &&
              finalBillAmount && finalBillAmount !== null) ? 'Total Payable' :
              totalprice && totalprice !== null &&
              'Total Payable'}</b>
          </span>
          <span className={classes.total}>
            <b> ₹ {(delivery && delivery !== null &&
              finalBillAmount && finalBillAmount !== null) ?
              parseFloat(Number(finalBillAmount)).toFixed(2)
               :
             (
              props?.OrderDetails?.shoppingTotalPayable?
              parseFloat(Number(props?.OrderDetails?.shoppingTotalPayable)).toFixed(2)
              :

               totalprice && totalprice !== null &&
              parseFloat(Number(delivery === 0 ? total : totalprice)).toFixed(2)
            )
              }</b>
          </span>
        </div>
  }
      </div>
      <div className={classes.orderStatus}>
        <Label color={(props.socket&&status!==null&&
          liveorderdata&&liveorderdata!==null&&
          liveorderdata.lastStatus)?
          liveorderdata.lastStatus:Status}>
          { (props.socket&&status!==null&&
          liveorderdata&&liveorderdata!==null&&
          liveorderdata.lastStatus)?
          liveorderdata.lastStatus
        :
          text
          }
        </Label>
      </div>
      <Dialog
        open={reviewopen}
        onClose={handleReviewClose}
        transitionDuration={1000}
        disableBackdropClick={true}
        fullScreen={fullScreen}
        classes={{ paper: classes.paper }}
      >
        <DialogTitle id="alert-dialog-slide-title">
          <Title>{"Areas to improve"}</Title>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText
            id="alert-dialog-description"
            className="text-center"
          >
            <div className={classes.form}>
              <FormGroup>
                <FormControlLabel
                  control={
                    <GreenCheckbox
                      checked={state.delivery}
                      onChange={handleChange}
                      name="delivery"
                      value="On time delivery"
                    />
                  }
                  label="On time delivery"
                />
                <FormControlLabel
                  control={
                    <GreenCheckbox
                      checked={state.packaging}
                      onChange={handleChange}
                      name="packaging"
                      value="Proper packaging"
                    />
                  }
                  label="Proper packaging"
                />
                <FormControlLabel
                  control={
                    <GreenCheckbox
                      checked={state.quality}
                      onChange={handleChange}
                      name="quality"
                      value="Quality of the items"
                    />
                  }
                  label="Quality of the items"
                />
                <FormControlLabel
                  control={
                    <GreenCheckbox
                      checked={state.quantity}
                      onChange={handleChange}
                      name="quantity"
                      value="Quantity of the items"
                    />
                  }
                  label="Quantity of the items"
                />
                <FormControlLabel
                  control={
                    <GreenCheckbox
                      checked={state.behaviour}
                      onChange={handleChange}
                      name="behaviour"
                      value="Behavior of the delivery person"
                    />
                  }
                  label="Behavior of the delivery person"
                />
              </FormGroup>
            </div>
            <div className={classes.form}>
              <TextField
                fullWidth
                label="Comments, if any"
                multiline
                rows={4}
                variant="outlined"
                onChange={(e) => handlecommentChange(e)}
              />
            </div>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <div style={{ textAlign: "center", alignItems: 'center', justifyContent: 'center', display: 'flex', width: '100%' }}>
            <button
              onClick={() => rating(state)}
              style={{
                backgroundColor: 'rgb(137, 199, 74, 1)',
                border: "none",
                color: "#fff",
                fontWeight: 600,
                borderRadius: '12px',
                display: 'flex',
                textAlign: 'center',
                alignItems: 'center',
                justifyContent: 'center',
                paddingTop: '8px',
                paddingBottom: '8px',
                paddingLeft: '20px',
                paddingRight: '20px',
                width: '80%',
              }}
            >
              Submit
            </button>
          </div>
        </DialogActions>
      </Dialog>
      <Dialog
        open={feedbackopen}
        onClose={handleFeedbackClose}
        PaperProps={{
          style: {
            borderRadius: '10px',
            backgroundColor: '#ffffff',
            width: '100%',
          }
        }}
        maxWidth={'xs'}
        transitionDuration={1000}
      >
        <DialogContent>
          <DialogContentText
            id="alert-dialog-description"
            className="text-center"
          >
            <div style={{ textAlign: "right" }}>
              <button
                onClick={handleFeedbackClose}
                style={{
                  backgroundColor: "transparent",
                  border: "none",
                  color: "rgb(137, 199, 74, 1)",
                  fontWeight: 600,
                }}
              >
                X
              </button>
            </div>
            <div className='ModalContent'>
              Thank you for your feedback !!!
            </div>
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default OrderCard