import React from 'react';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import { AppBar, Drawer, Grid, Box, Divider, useMediaQuery, useTheme, Dialog } from '@mui/material';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { useStyles } from './style';
import Loader from '../LoaderGif';

const PreviousComplaints = (props) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const [selectedComplaintIndex, setselectedComplaintIndex] = React.useState(0);
    const theme = useTheme();
    const desktop = useMediaQuery(theme.breakpoints.up('md'));

    const openDrawer = (index) => {
        setOpen(true);
        setselectedComplaintIndex(index);
    };
    const drawerClose = () => {
        setOpen(false);
    };
    return (
        <>
            {props.previousComplaints && props.previousComplaints === true &&
                props.OrderComplaints && props.OrderComplaints !== null && props.OrderComplaints.length === 0 &&
                <div style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                No Previous Complaints
                </div>
                }
            {props.previousComplaintsLoading && props.previousComplaintsLoading === true ?
               <Loader/>
                :
                props.previousComplaints && props.previousComplaints === true &&
                props.OrderComplaints && props.OrderComplaints !== null && props.OrderComplaints.length > 0 &&
                props.OrderComplaints.map((complaint, index) =>
                    <div className={classes.previousComplaintsDiv} onClick={() => openDrawer(index)}>
                        <div className={classes.previousComplaintsHeading}>
                            <span className={classes.previousComplaintsMainmessage}>
                                {complaint.userMessage !== null &&
                                    complaint.userMessage[0]}
                            </span>
                            <span className={classes.previousComplaintsOrderNumber}>
                                {`Order #${complaint.orderid && complaint.orderid.orderNumber && complaint.orderid.orderNumber} | Complaint Status : 
                                    ${complaint.complaintStatus && complaint.complaintStatus === 1 ? 'Open' : 'Closed'}`}
                            </span>
                        </div>
                        <span className={classes.previousComplaintsArrow}>
                            <ArrowForwardIosIcon fontSize={'14px'} />
                        </span>
                    </div>
                )
            }
            {desktop ?
                <Drawer
                    open={open}
                    onClose={drawerClose}
                    anchor='right'
                    sx={{
                        '& .MuiDrawer-paper':
                        {
                            boxSizing: 'border-box',
                            width: { xs: '100%', sm: '100%', md: 500 },
                            backgroundColor: '#EAEDEF'
                        },
                    }}
                >
                    <AppBar
                        position={'fixed'}
                        sx={{
                            top: 0,
                            bottom: "auto",
                            width: { xs: '100%', sm: '100%', md: 500 },
                            backgroundColor: "transparent",
                            border: 'none',
                            boxShadow: 'none',
                            height: "100px",
                            paddingTop: "10px",
                            color: '#000',
                            justifyContent: 'center'
                        }}>
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: '#000000'
                            }}>
                            <div style={{ paddingBottom: '10px', width: '100%' }}>
                                <Grid container style={{ width: '100%' }}>
                                    <Grid item xs={0.5} />
                                    <Grid item xs={2}
                                        justifyContent={'center'} textAlign={'center'} alignItems={'center'}>
                                        <span className={classes.leftarrow} onClick={() => drawerClose()}>
                                            <KeyboardBackspaceIcon />
                                        </span>
                                    </Grid>
                                    <Grid item xs={1} />
                                    <Grid item xs={8} 
                                        style={{ width: '100%',justifyContent:"center",alignItems:"center",textAlign:"center" }}>
                                        <div style={{ display: 'flex', width: '100%', justifyContent: 'center', alignItems: 'center' }}>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <span
                                                    style={{
                                                        color: '#000000',
                                                        fontStyle: 'normal',
                                                        fontWeight: 'bold',
                                                        fontSize: '24px',
                                                        lineHeight: '33px',
                                                    }}>
                                                    {'Support'}
                                                </span>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </AppBar>
                    <Box className={classes.complaintDetailsDiv}>
                        {props.previousComplaints && props.previousComplaints === true &&
                            props.OrderComplaints && props.OrderComplaints !== null && props.OrderComplaints.length > 0 &&
                            props.OrderComplaints[selectedComplaintIndex] !== null &&
                            <>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} md={6} className={classes.leftAlignGrid}>
                                        {'Complaint Status'}
                                    </Grid>
                                    <Grid item xs={6} md={6} className={classes.rightAlignGrid}>
                                        {props.OrderComplaints[selectedComplaintIndex].complaintStatus === 1 ? 'Open' : 'Closed'}
                                    </Grid>
                                    {props.OrderComplaints[selectedComplaintIndex].refund === true &&
                                        <>
                                            <Grid item xs={6} md={6} className={classes.leftAlignGrid}>
                                                {'Refund Status'}
                                            </Grid>
                                            <Grid item xs={6} md={6} className={classes.rightAlignGrid}>
                                                {props.OrderComplaints[selectedComplaintIndex].refundStatus
                                                }
                                            </Grid>
                                        </>
                                    }
                                    {props.OrderComplaints[selectedComplaintIndex].replacement === true &&
                                        <>
                                            <Grid item xs={6} md={6} className={classes.leftAlignGrid}>
                                                {'Replacement Status'}
                                            </Grid>
                                            <Grid item xs={6} md={6} className={classes.rightAlignGrid}>
                                                {props.OrderComplaints[selectedComplaintIndex].replacementStatus
                                                }
                                            </Grid>
                                        </>
                                    }
                                </Grid>
                                <Grid item xs={12} md={12} sx={{ paddingTop: '25px' }} />
                                <Divider />
                                <Grid item xs={12} md={12} className={classes.headingGrid}>
                                    {'Conversation'}
                                </Grid>
                                {props.OrderComplaints[selectedComplaintIndex].userMessage.length > 0 &&
                                    props.OrderComplaints[selectedComplaintIndex].userMessage.map((message, index) =>
                                        <Grid item xs={12} md={12} key={index}>
                                            <Grid container style={{ paddingBottom: '20px' }}>
                                                <Grid item xs={1} md={1} className={classes.messageGrid}>
                                                    <AccountCircleIcon fontSize='medium' color='inherit' />
                                                </Grid>
                                                <Grid item xs={11} md={11} >
                                                    <div className={classes.usercomplaintdiv}>
                                                        {message}
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                }
                                <Grid item xs={12} md={12} sx={{ paddingTop: '25px' }} />
                                {props.OrderComplaints[selectedComplaintIndex].itemList !== null &&
                                    props.OrderComplaints[selectedComplaintIndex].itemList.length > 0 &&
                                    <>
                                        <Divider>
                                            {'Selected Items'}
                                        </Divider>
                                        {props.OrderComplaints[selectedComplaintIndex].itemList.map((item, index) =>
                                            <Grid item xs={12} md={12} key={index} sx={{ paddingTop: '20px' }}>
                                                <Grid container style={{ paddingBottom: '20px' }}>
                                                    <Grid item xs={11} md={11} justifyContent='right'>
                                                        <div className={classes.itemlistdiv}>
                                                            {item}
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </>
                                }
                                <Grid item xs={12} md={12} sx={{ paddingTop: '25px' }} />
                                {props.OrderComplaints[selectedComplaintIndex].image !== null &&
                                    props.OrderComplaints[selectedComplaintIndex].image !== undefined &&
                                    <>
                                        <Divider>
                                            {'Uploaded image'}
                                        </Divider>
                                        <Grid item xs={12} md={12} sx={{ paddingTop: '20px', display: 'flex' }} justifyContent='center' alignItems='center'>
                                            <img src={props.OrderComplaints[selectedComplaintIndex].image}
                                                alt='complaint_image' className={classes.complaintImage} />
                                        </Grid>
                                    </>
                                }
                                <Grid item xs={12} md={12} sx={{ paddingTop: '25px' }} />
                                {(props.OrderComplaints[selectedComplaintIndex].refund === true ||
                                    props.OrderComplaints[selectedComplaintIndex].replacement === true) ?
                                    <Grid item xs={12} md={12} justifyContent='right'>
                                        <div className={classes.usercomplaintdiv}>
                                            Selected Option : {props.OrderComplaints[selectedComplaintIndex].refund === true ?
                                                'Refund' :
                                                props.OrderComplaints[selectedComplaintIndex].replacement === true ?
                                                    'Replacement' :
                                                    null}
                                        </div>
                                    </Grid>
                                    :
                                    null
                                }
                            </>
                        }
                    </Box>
                </Drawer>
                :
                <Dialog
                    PaperProps={{
                        style: {
                            backgroundColor: '#fff',
                            width: '100%',
                        }
                    }}
                    fullScreen={true}
                    maxWidth={'sm'}

                    open={open}
                    onClose={drawerClose}
                >
                    <AppBar
                        position={'fixed'}
                        sx={{
                            top: 0,
                            bottom: "auto",
                            width: { xs: '100%', sm: '100%', md: 500 },
                            backgroundColor: "transparent",
                            border: 'none',
                            boxShadow: 'none',
                            height: "100px",
                            paddingTop: "10px",
                            color: '#000',
                            justifyContent: 'center'
                        }}>
                        <div
                            style={{
                                width: '100%',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                color: '#000000'
                            }}>
                            <div style={{ paddingBottom: '10px', width: '100%' }}>
                                <Grid container style={{ width: '100%' }}>
                                    <Grid item xs={0.5} />
                                    <Grid item xs={2}
                                        justifyContent={'center'} textAlign={'center'} alignItems={'center'}>
                                        <span className={classes.leftarrow} onClick={() => drawerClose()}>
                                            <KeyboardBackspaceIcon />
                                        </span>
                                    </Grid>
                                    <Grid item xs={1} />
                                    <Grid item xs={8} 
                                        style={{ width: '100%',justifyContent:"center",textAlign:"center",alignItems:"center" }}>
                                        <div style={{ display: 'flex', width: '100%', justifyContent: 'left', alignItems: 'center' }}>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                <span
                                                    style={{
                                                        color: '#000000',
                                                        fontStyle: 'normal',
                                                        fontWeight: 'bold',
                                                        fontSize: '24px',
                                                        lineHeight: '33px',
                                                    }}>
                                                    {'Support'}
                                                </span>
                                            </div>
                                        </div>
                                    </Grid>
                                </Grid>
                            </div>
                        </div>
                    </AppBar>
                    <Box className={classes.complaintDetailsDiv}>
                        {props.previousComplaints && props.previousComplaints === true &&
                            props.OrderComplaints && props.OrderComplaints !== null && props.OrderComplaints.length > 0 &&
                            props.OrderComplaints[selectedComplaintIndex] !== null &&
                            <>
                                <Grid container spacing={2}>
                                    <Grid item xs={6} md={6} className={classes.leftAlignGrid}>
                                        {'Complaint Status'}
                                    </Grid>
                                    <Grid item xs={6} md={6} className={classes.rightAlignGrid}>
                                        {props.OrderComplaints[selectedComplaintIndex].complaintStatus === 1 ? 'Open' : 'Closed'}
                                    </Grid>
                                    {props.OrderComplaints[selectedComplaintIndex].refund === true &&
                                        <>
                                            <Grid item xs={6} md={6} className={classes.leftAlignGrid}>
                                                {'Refund Status'}
                                            </Grid>
                                            <Grid item xs={6} md={6} className={classes.rightAlignGrid}>
                                                {props.OrderComplaints[selectedComplaintIndex].refundStatus
                                                }
                                            </Grid>
                                        </>
                                    }
                                    {props.OrderComplaints[selectedComplaintIndex].replacement === true &&
                                        <>
                                            <Grid item xs={6} md={6} className={classes.leftAlignGrid}>
                                                {'Replacement Status'}
                                            </Grid>
                                            <Grid item xs={6} md={6} className={classes.rightAlignGrid}>
                                                {props.OrderComplaints[selectedComplaintIndex].replacementStatus
                                                }
                                            </Grid>
                                        </>
                                    }
                                </Grid>
                                <Grid item xs={12} md={12} sx={{ paddingTop: '25px' }} />
                                <Divider />
                                <Grid item xs={12} md={12} className={classes.headingGrid}>
                                    {'Conversation'}
                                </Grid>
                                {props.OrderComplaints[selectedComplaintIndex].userMessage.length > 0 &&
                                    props.OrderComplaints[selectedComplaintIndex].userMessage.map((message, index) =>
                                        <Grid item xs={12} md={12} key={index}>
                                            <Grid container style={{ paddingBottom: '20px' }}>
                                                <Grid item xs={2} md={1} style={{paddingTop:"5px"}} className={classes.messageGrid}>
                                                    <AccountCircleIcon fontSize='medium' color='inherit' />
                                                </Grid>
                                                <Grid item xs={10} md={11} >
                                                    <div className={classes.usercomplaintdiv}>
                                                        {message}
                                                    </div>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    )
                                }
                                <Grid item xs={12} md={12} sx={{ paddingTop: '25px' }} />
                                {props.OrderComplaints[selectedComplaintIndex].itemList !== null &&
                                    props.OrderComplaints[selectedComplaintIndex].itemList.length > 0 &&
                                    <>
                                        <Divider>
                                            {'Selected Items'}
                                        </Divider>
                                        {props.OrderComplaints[selectedComplaintIndex].itemList.map((item, index) =>
                                            <Grid item xs={12} md={12} key={index} sx={{ paddingTop: '20px' }}>
                                                <Grid container style={{ paddingBottom: '20px' }}>
                                                    <Grid item xs={11} md={11} justifyContent='right'>
                                                        <div className={classes.itemlistdiv}>
                                                            {item}
                                                        </div>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                        )}
                                    </>
                                }
                                <Grid item xs={12} md={12} sx={{ paddingTop: '25px' }} />
                                {props.OrderComplaints[selectedComplaintIndex].image !== null &&
                                    props.OrderComplaints[selectedComplaintIndex].image !== undefined &&
                                    <>
                                        <Divider>
                                            {'Uploaded image'}
                                        </Divider>
                                        <Grid item xs={12} md={12} sx={{ paddingTop: '20px', display: 'flex' }} justifyContent='center' alignItems='center'>
                                            <img src={props.OrderComplaints[selectedComplaintIndex].image}
                                                alt='complaint_image' className={classes.complaintImage} />
                                        </Grid>
                                    </>
                                }
                                <Grid item xs={12} md={12} sx={{ paddingTop: '25px' }} />
                                {(props.OrderComplaints[selectedComplaintIndex].refund === true ||
                                    props.OrderComplaints[selectedComplaintIndex].replacement === true) ?
                                    <Grid item xs={12} md={12} justifyContent='right'>
                                        <div className={classes.usercomplaintdiv}>
                                            Selected Option : {props.OrderComplaints[selectedComplaintIndex].refund === true ?
                                                'Refund' :
                                                props.OrderComplaints[selectedComplaintIndex].replacement === true ?
                                                    'Replacement' :
                                                    null}
                                        </div>
                                    </Grid>
                                    :
                                    null
                                }
                            </>
                        }
                    </Box>
                </Dialog>
            }
        </>
    )
}

export default PreviousComplaints