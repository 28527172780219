/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { useEffect } from "react";
import { useStyles } from "./style.js";
import errorproduct from "../../Assets/Images/errorproduct.svg";
import emptycart from "../../Assets/Images/emptycart.svg";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Tooltip,
} from "@mui/material";
import {
  CHECK_MIN_AMOUNT,
  UPDATE_FULL_CART,
} from "../../Redux/Actions/Queries";
import client from "../../Configurations/apollo";
import {
  PLACEORDER_ADDRESS,
  RESTRICT_ORDER,
} from "../../Redux/Actions/Queries";
import AlertBar from "../AlertBar/index.js";
import { AppBar } from "@mui/material";
import { db } from "../db.js";
import { useLiveQuery } from "dexie-react-hooks";
import removestore from "../../Assets/Images/removestore.svg";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import AddButton from "../AddButton";
import { useMediaQuery, useTheme } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ProductDetails from "../ProductDetails/index.js";
import LoginOrSignupModal from "../Forms/LoginOrSignupModal";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import "./index.css";

const CartList = (props) => {
  const classes = useStyles();
  const [deletestoreopen, setDeleteStoreOpen] = React.useState(false);
  const [productdetailsopen, setProductOpen] = React.useState(false);
  const [isButtonDisabled, setButtonDisabled] = React.useState(false);
  const [disablebuynowbutton, setDisableBuynowButton] = React.useState(false);
  const [addressopen, setAddressOpen] = React.useState(false);
  const [openRestrictOrder, setOpenRestrictOrder] = React.useState(false);
  const [checkopen, setCheckOpen] = React.useState(false);
  const [removeitemopen, setRemoveItemOpen] = React.useState(false);
  const [loginpopupopen, setLoginPopupOpen] = React.useState(false);
  const [updateFullCartErrorOpen, setupdateFullCartErrorOpen] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const { cart } = db;
  const cartdata = useLiveQuery(() => cart.toArray(), []);
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const handleProductDetailsClose = () => {
    setProductOpen(false);
  };
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [state, setState] = React.useState({
    data: {},
    CheckAmount: [],
    cartid: "",
    storeid: "",
    storename: "",
    storelogo: "",
    storelength: "",
    deleteStore: false,
    error: "",
    restrictordernum: 0,
    right: false,
    productid: "",
    productname: "",
    productimage: "",
  });
  const RemoveStoreModalOpen = (id, storeID, store, logo, len, total) => {
    setState({
      ...state,
      cartid: id,
      storeid: storeID,
      storename: `${store}`,
      storelogo: `${logo}`,
      storelength: Number(len),
      total: Number(total),
    });
    setDeleteStoreOpen(true);
  };
  const handleStoreClose = () => {
    setDeleteStoreOpen(false);
  };
  const debounce = (func, delay) => {
    let timer;
    return function (...args) {
      clearTimeout(timer);
      timer = setTimeout(() => func(...args), delay);
    };
  };
  const placeorderCheck = debounce((id, lat, lng, cartdata) => {
    if (!isButtonDisabled) {
      if (
        !localStorage.getItem("token") ||
        (props.isCartFailed === true && props.carterror === "Unauthenticated!")
      ) {
        setLoginPopupOpen(true);
      } else {
        if (cartdata.length > 1) {
          alert(
            "Cannot add more than one store at a time. Please remove any store from cart to continue"
          );
          return; // Exit the function
        } else {
          client
            .mutate({
              mutation: UPDATE_FULL_CART,
              variables: {
                userid: id,
                storeid: cartdata[0].storeid,
                products: cartdata[0].products.map((c) => ({
                  productid: c.productid,
                  productPrice: Number(c.productPrice),
                  quantity: c.quantity,
                  price: (c.quantity * Number(c.productPrice)).toString(),
                })),
              },
            })
            .then((res) => {
              if (res.data) {
                if (
                  res.data &&
                  res.data !== null &&
                  res.data.updateFullCart &&
                  res.data.updateFullCart !== null
                ) {
                  client
                    .query({
                      query: RESTRICT_ORDER,
                    })
                    .then((res) => {
                      if (res.data) {
                        if (cartdata.length <= 10 - res.data.restrictOrder) {
                          setState({
                            ...state,
                            restrictordernum: Number(
                              10 - res.data.restrictOrder
                            ),
                          });
                          client
                            .query({
                              query: CHECK_MIN_AMOUNT,
                            })
                            .then((res) => {
                              if (
                                res.data &&
                                res.data.checkMinAmount &&
                                !res.data.checkMinAmount.length
                              ) {
                                client
                                  .query({
                                    query: PLACEORDER_ADDRESS,
                                    variables: {
                                      userid: `${id}`,
                                      lat: Number(lat),
                                      lng: Number(lng),
                                    },
                                  })
                                  .then((res) => {
                                    if (
                                      res.data &&
                                      res.data.placeOrderNewAddressCheck &&
                                      res.data.placeOrderNewAddressCheck
                                        .length === 0
                                    ) {
                                      props.navigate("/checkout");
                                    } else if (
                                      res.data &&
                                      res.data.placeOrderNewAddressCheck &&
                                      res.data.placeOrderNewAddressCheck
                                        .length > 0
                                    ) {
                                      setAddressOpen(true);
                                      setTimeout(() => {
                                        setAddressOpen(false);
                                      }, 2000);
                                      setState({ ...state, right: true });
                                      setState({ data: res.data });
                                    } else if (res.errors) {
                                      console.log(res.errors[0].message);
                                    }
                                  })
                                  .catch((err) => {
                                    return null;
                                  });
                              } else if (
                                res.data &&
                                res.data.checkMinAmount &&
                                res.data.checkMinAmount.length &&
                                res.data.checkMinAmount.length > 0
                              ) {
                                setCheckOpen(true);
                                setState({
                                  ...state,
                                  CheckAmount: res.data.checkMinAmount,
                                });
                                setTimeout(() => {
                                  setCheckOpen(false);
                                }, 3000);
                              } else if (res.errors) {
                                console.log(res.errors[0].message);
                              }
                            })
                            .catch((err) => {
                              console.log(err);
                            });
                        } else if (
                          cartdata.length >
                          10 - res.data.restrictOrder
                        ) {
                          setOpenRestrictOrder(true);
                          setTimeout(() => {
                            setOpenRestrictOrder(false);
                          }, 3000);
                        }
                      }
                      if (res.errors) {
                        alert(res.errors[0].message);
                      }
                    })
                    .catch((err) => {
                      console.log(err);
                    });
                } else if (
                  res.data &&
                  res.data.updateFullCart &&
                  res.data.updateFullCart === null
                ) {
                  return null;
                } else if (res.errors) {
                  console.log(res.errors);
                }
              } else if (res.errors) {
                console.log(res.errors);
              }
            })
            .catch((err) => {
              setSnackbarMessage(err.message || "An error occurred");
              setSnackbarOpen(true);
            });
        }
        setButtonDisabled(true);
        setTimeout(() => {
          setButtonDisabled(false);
        }, 1000);
      }
    }
  }, 300);

  const calCulateStoretotal = (products) => {
    let t = 0;
    products.map(
      (p) => (t = t + Number(p.cartquantity) * Number(p.productprice))
    );
    return t;
  };
  const removestorefromcart = async (storeid) => {
    await db.cart.delete(storeid);
    setDeleteStoreOpen(false);
  };
  const calCulateTotal = (data) => {
    let total = 0;
    data &&
      data !== null &&
      data.length > 0 &&
      data.map(({ products }) =>
        products.map(
          (p) =>
            (total = total + Number(p.cartquantity) * Number(p.productprice))
        )
      );
    return total;
  };

  useEffect(
    () => {
      props.getCart(localStorage.getItem("userid"));
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  return (
    <React.Fragment>
      {cartdata && cartdata !== null && cartdata.length > 0 ? (
        cartdata.map((cart) => (
          <>
            <div
              style={{
                background: "transparent",
                width: "100%",
                paddingBottom: "50px",
              }}
            >
              <div
                style={{
                  background: "#ffffff",
                  borderRadius: "13px",
                  width: "100%",
                }}
              >
                <div className={classes.cartStoreHeading}>
                  <span className={classes.cartStoreName}>
                    <span>
                      <b>{cart.storename && cart.storename}</b>
                    </span>
                    <span className={classes.clearStorediv}>
                      <span className={classes.storetotal}>
                        {cart.products && cart.products.length}&nbsp;{"Items"}
                      </span>
                      &nbsp;
                      <span>
                        <button
                          className={classes.removestore}
                          style={{
                            cursor: "pointer",
                            border: "none",
                          }}
                          onClick={() =>
                            RemoveStoreModalOpen(
                              cart.id,
                              cart.storeid,
                              cart.storename,
                              cart.products.length,
                              cart.totalPrice
                            )
                          }
                        >
                          <DeleteForeverIcon fontSize="inherit" />
                          Remove
                        </button>
                      </span>
                    </span>
                  </span>
                  <span
                    className={classes.cartStoreTotal}
                    style={{
                      fontWeight: 800,
                      background: "rgba(255, 217, 81, 0.7)",
                      borderRadius: "15.5px",
                      color: "rgba(0,0,0,0.9)",
                    }}
                  >
                    <b>
                      {"₹ " +
                        parseFloat(
                          calCulateStoretotal(cart.products && cart.products)
                        ).toFixed(2)}
                    </b>
                  </span>
                </div>
                {cart.products &&
                  cart.products.map((product) => (
                    <div style={{ paddingBottom: "15px" }}>
                      <div className={classes.cartProductBox}>
                        <div className={classes.cartProductDetails}>
                          <span
                            style={{
                              float: "left",
                              cursor: "pointer",
                            }}
                            onClick={() => {
                              localStorage.setItem(
                                "productid",
                                product.productid
                              );
                              localStorage.setItem(
                                "storeid",
                                cart.storeid && cart.storeid
                              );
                              localStorage.setItem(
                                "productstoreid",
                                cart.storeid && cart.storeid
                              );
                              localStorage.setItem(
                                "productstorename",
                                cart.storename && cart.storename
                              );
                              props.getProductDetails({
                                userid: localStorage.getItem("userid"),
                                storeid: localStorage.getItem("productstoreid")
                                  ? localStorage.getItem("productstoreid")
                                  : localStorage.getItem("storeid"),
                                id: product.productid,
                              });
                              setProductOpen(true);
                            }}
                          >
                            {product.image === null ||
                            product.image === "null" ||
                            product.image === undefined ||
                            product.image === "undefined" ? (
                              <img
                                src={errorproduct}
                                className={classes.cartImage}
                                alt="product"
                              />
                            ) : (
                              <img
                                src={product.image && product.image}
                                className={classes.cartImage}
                                alt="product"
                              />
                            )}
                          </span>
                          <span
                            className={
                              fullScreen
                                ? classes.productDetailsDivSmallScreen
                                : classes.productDetailsDiv
                            }
                          >
                            <span className={classes.productName}>
                              <Tooltip
                                title={
                                  product.productname && product.productname
                                }
                              >
                                <span className="productname-height">
                                  <b>
                                    {product.productname && product.productname}
                                  </b>
                                </span>
                              </Tooltip>
                            </span>
                            {fullScreen ? (
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                }}
                              >
                                <span className={classes.cartPriceSmallScreen}>
                                  ₹
                                  {product.productprice &&
                                    parseFloat(
                                      product.productprice *
                                        Number(product.cartquantity)
                                    ).toFixed(2)}
                                </span>
                                <span className={classes.productprice}>
                                  <b>
                                    ₹{product.productprice.toFixed(2)}
                                    {" per"}&nbsp;
                                    {product.productQuantity &&
                                      product.productQuantity}
                                    {product.uom && product.uom}
                                  </b>
                                </span>
                              </div>
                            ) : (
                              <span className={classes.productprice}>
                                <b>
                                  ₹{product.productprice.toFixed(2)}
                                  {" per"}&nbsp;
                                  {product.productQuantity &&
                                    product.productQuantity}
                                  {product.uom && product.uom}
                                </b>
                              </span>
                            )}
                          </span>
                          <span>
                            <AddButton
                              storeid={cart.storeid}
                              id={product.productid}
                              image={product.image}
                              productname={product.productname}
                              price={Number(product.markedupProductPrice)}
                              markedupProductPrice={Number(
                                product?.markedupProductPrice
                              )}
                              promoprice={null}
                              storename={cart.storename}
                              uom={product.uom}
                              quantity={product.productQuantity}
                              application={"cart"}
                              {...props}
                            />
                          </span>
                          {fullScreen ? null : (
                            <span className={classes.cartPrice}>
                              ₹
                              {product.productprice &&
                                parseFloat(
                                  product.productprice *
                                    Number(product.cartquantity)
                                ).toFixed(2)}
                            </span>
                          )}
                        </div>
                      </div>
                      <Divider />
                    </div>
                  ))}
              </div>
            </div>
            <AppBar
              position={"fixed"}
              sx={{
                top: "auto",
                bottom: 0,
                width: { xs: "100%", sm: "100%", md: 500 },
                backgroundColor: "transparent",
                border: "none",
                boxShadow: "none",
                height: "auto",
                paddingTop: "10px",
              }}
            >
              <div
                style={{
                  bottom: 0,
                  background:
                    " linear-gradient(180deg, #FFD951 0%, #FFC800 100%)",
                  width: "100%",
                  display: "flex",
                  paddingTop: "11px",
                  paddingBottom: "15px",
                  justifyContent: "center",
                  alignItems: "center",
                  paddingLeft: "18px",
                  paddingRight: "34px",
                }}
              >
                <span
                  style={{
                    float: "left",
                    fontWeight: 600,
                    fontSize: "14px",
                    lineHeight: "22px",
                    color: "#061303",
                    width: "auto",
                    paddingLeft: "20px",
                  }}
                >
                  {"Total"}
                </span>
                <span
                  style={{
                    fontWeight: 1500,
                    textAlign: "left",
                    color: "#061303",
                    width: "100%",
                    fontSize: "18px",
                    paddingLeft: "5px",
                    lineHeight: "22px",
                  }}
                >
                  <b>
                    {" ₹"}
                    {parseFloat(calCulateTotal(cartdata && cartdata)).toFixed(
                      2
                    )}
                  </b>
                </span>
                <span
                  style={{
                    float: "right",
                    width: "100%",
                    display: "flex",
                    justifyContent: "end",
                    paddingRight: "1px",
                  }}
                >
                  {console.log(
                    "carLogin",
                    localStorage.getItem("token"),
                    "props",
                    props
                  )}
                  {!localStorage.getItem("token") ||
                  (props.isCartFailed === true &&
                    props.carterror === "Unauthenticated!") ? (
                    <LoginOrSignupModal componentkey={"checkout"} {...props} />
                  ) : localStorage.getItem("userid") ? (
                    <button
                      disabled={isButtonDisabled}
                      onClick={() => {
                        localStorage.getItem("userid")
                          ? placeorderCheck(
                              localStorage.getItem("userid"),
                              localStorage.getItem("lattitude"),
                              localStorage.getItem("longitude"),
                              cartdata &&
                                cartdata !== null &&
                                cartdata.length > 0
                                ? cartdata.map((c) => ({
                                    storeid: c.storeid,
                                    totalPrice: calCulateStoretotal(c.products),
                                    products: c.products.map((p) => ({
                                      productid: p.productid,
                                      productPrice: Number(p.productprice),
                                      quantity: p.cartquantity,
                                      price: (
                                        p.cartquantity * p.productprice
                                      ).toString(),
                                    })),
                                  }))
                                : []
                            )
                          : setLoginPopupOpen(false);
                      }}
                      style={{
                        background: "rgba(255, 255, 255, 0.5)",
                        borderRadius: "18px",
                        fontWeight: 600,
                        fontSize: "14px",
                        lineHeight: "22px",
                        color: "#061303",
                        border: "none",
                        cursor: "pointer",
                        paddingLeft: "15px",
                        paddingRight: "15px",
                        paddingTop: "5px",
                        paddingBottom: "5px",
                      }}
                    >
                      {"Buy Now"}
                    </button>
                  ) : (
                    <LoginOrSignupModal componentkey={"checkout"} {...props} />
                  )}
                </span>
              </div>
            </AppBar>
          </>
        ))
      ) : (
        <div className={classes.noordersDiv}>
          <img
            src={emptycart}
            alt="noorders"
            className={classes.noordersimage}
          />
          <p className={classes.noordersheading}>
            {"Cart is empty, Please add some items"}
          </p>
        </div>
      )}
      {addressopen && (
        <AlertBar
          type={"error"}
          props={props}
          {...props}
          message={
            state.data &&
            state.data.placeOrderNewAddressCheck &&
            state.data.placeOrderNewAddressCheck.length > 0 &&
            state.data.placeOrderNewAddressCheck.map(
              (store, i) =>
                cartdata &&
                cartdata.length >= 1 &&
                cartdata.map((c) =>
                  c.storeid === store
                    ? `${c.storename}${
                        Number(i) !==
                        Number(state.data.placeOrderNewAddressCheck.length - 1)
                          ? ","
                          : " is not deliverable in the selected location"
                      }`
                    : null
                )
            )
          }
        />
      )}
      {checkopen && (
        <AlertBar
          type={"error"}
          message={
            state.CheckAmount &&
            state.CheckAmount.length &&
            state.CheckAmount.length > 0 &&
            state.CheckAmount.map((msg) => msg)
          }
          props={props}
          {...props}
        />
      )}
      {openRestrictOrder && (
        <AlertBar
          type={"error"}
          {...props}
          props={props}
          message={
            state.restrictordernum && state.restrictordernum > 0
              ? `Exceeded maximum number of orders for today!  You may place order from ${state.restrictordernum} more store  `
              : `Exceeded maximum number of orders for today!`
          }
        />
      )}
      <Dialog
        PaperProps={{
          style: {
            borderRadius: "13px",
            backgroundColor: "#ffffff",
            width: "450px",
            padding: "25px",
          },
        }}
        // fullScreen={fullScreen}
        maxWidth={"md"}
        open={deletestoreopen}
        onClose={handleStoreClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
          }}
        >
          <img src={removestore} alt={`remove ${state.storename}`} />
          <div
            style={{
              color: "#000000",
              fontSize: "18px",
              fontWeight: 600,
              paddingTop: "20px",
              fontFamily: "Nunito",
            }}
          >
            {`You are removing all items from this store`}
          </div>
          <div
            style={{
              color: "rgba(89, 98, 106, 0.8)",
              fontSize: "14px",
              lineHeight: "19px",
              paddingTop: "10px",
              fontFamily: "Nunito",
            }}
          >
            {`This will remove all items from ${state.storename}`}
          </div>
        </DialogTitle>
        <DialogContent
          style={{
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            display: "flex",
          }}
        >
          <div
            style={{
              width: "80%",
              marginTop: "15px",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              paddingTop: "8px",
              display: "flex",
            }}
          >
            <button className={classes.buttonCancel} onClick={handleStoreClose}>
              {"Cancel"}
            </button>
            <button
              className={classes.button}
              onClick={() =>
                removestorefromcart(state.storeid && state.storeid)
              }
            >
              {"Remove"}
            </button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        PaperProps={{
          style: {
            borderRadius: "26.5px",
            backgroundColor: `#EAEEF7`,
            width: "450px",
            padding: "25px",
          },
        }}
        // fullScreen={fullScreen}
        maxWidth={"sm"}
        open={removeitemopen}
        onClose={() => setRemoveItemOpen(false)}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle style={{ fontSize: "14px" }}>
          {`Are you sure you want to remove ${state.productname} from cart ?`}
        </DialogTitle>
        <DialogContent
          style={{
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            display: "flex",
          }}
        >
          <div
            style={{
              width: "80%",
              marginTop: "15px",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              paddingTop: "8px",
              display: "flex",
            }}
          >
            <button
              className={classes.buttonCancel}
              onClick={() => {
                props.removeproductClick(
                  localStorage.getItem("userid"),
                  state.productid,
                  state.storeid
                );
                setRemoveItemOpen(false);
              }}
            >
              Yes
            </button>
            <button
              className={classes.button}
              onClick={() => setRemoveItemOpen(false)}
            >
              No
            </button>
          </div>
        </DialogContent>
      </Dialog>

      {/* //LOGIN_OR_SIGNUP */}

      <Dialog
        PaperProps={{
          style: {
            borderRadius: "26.5px",
            backgroundColor: `#EAEEF7`,
            width: "450px",
            padding: "25px",
          },
        }}
        // fullScreen={fullScreen}
        maxWidth={"sm"}
        open={loginpopupopen}
        onClose={handleStoreClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle style={{ fontSize: "14px" }}>
          {`Login or Signup`}
        </DialogTitle>
        <DialogContent
          style={{
            justifyContent: "center",
            alignItems: "center",
            textAlign: "center",
            display: "flex",
          }}
        >
          <div
            style={{
              width: "80%",
              marginTop: "15px",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              paddingTop: "8px",
              display: "flex",
            }}
          >
            {"Login or Signup"}
            <Divider />
            <button
              onClick={() => {
                localStorage.clear();
                props.history.push("/");
              }}
              style={{
                background: "linear-gradient(180deg, #85BE49 0%, #69A85C 100%)",
                width: "70%",
                cursor: "pointer",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                paddingTop: "15px",
                paddingBottom: "15px",
                fontSize: "18px",
                fontWeight: 700,
                color: "#ffffff",
                lineHeight: "25px",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                border: "none",
              }}
            >
              {"Login"}
            </button>
            <div
              style={{
                paddingTop: "20px",
                paddingBottom: "20px",
              }}
            >
              {"Or"}
            </div>
            <button
              onClick={() => props.history.push("/")}
              style={{
                background: "linear-gradient(180deg, #85BE49 0%, #69A85C 100%)",
                width: "70%",
                cursor: "pointer",
                justifyContent: "center",
                alignItems: "center",
                textAlign: "center",
                paddingTop: "15px",
                paddingBottom: "15px",
                fontSize: "18px",
                fontWeight: 700,
                color: "#ffffff",
                lineHeight: "25px",
                boxShadow: "0px 4px 4px rgba(0, 0, 0, 0.25)",
                border: "none",
              }}
            >
              {"Register"}
            </button>
          </div>
        </DialogContent>
      </Dialog>
      <Dialog
        PaperProps={{
          style: {
            borderRadius: "10px",
            backgroundColor: "#ffffff",
            width: "100%",
            height: "80%",
          },
        }}
        fullScreen={fullScreen}
        maxWidth={"md"}
        scroll={"paper"}
        open={productdetailsopen}
        onClose={handleProductDetailsClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle className={classes.modalHeading}>
          <span
            style={{
              width: "25px",
              float: "right",
              color: "#ffffff",
              borderRadius: "50%",
              fontSize: "14px",
              height: "25px",
              background: "#BDBDBD",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <CloseIcon
              style={{
                cursor: "pointer",
                fontSize: "14px",
              }}
              onClick={handleProductDetailsClose}
            />
          </span>
        </DialogTitle>
        <DialogContent>
          <ProductDetails
            storename={localStorage.getItem("productstorename")}
            props={props}
          />
        </DialogContent>
      </Dialog>
      {
        <Snackbar
          open={snackbarOpen}
          autoHideDuration={5000}
          onClose={() => setSnackbarOpen(false)}
          anchorOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <MuiAlert
            onClose={() => setSnackbarOpen(false)}
            severity="error"
            sx={{
              backgroundColor: "#d32f2f",
              borderRadius: "6px",
              color: "#fff",
              boxShadow:
                "0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 6px 10px 0px rgb(0 0 0 / 14%), 0px 1px 18px 0px rgb(0 0 0 / 12%)",
            }}
          >
            {snackbarMessage}
          </MuiAlert>
        </Snackbar>
      }
    </React.Fragment>
  );
};

export default CartList;
