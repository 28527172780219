import React from 'react';
import Loader from '../Assets/NearshopzLoader.gif';

const LoaderGif = () => {
  return (
    <div style={{ 
      display: 'flex', 
      justifyContent: 'center', 
      alignItems: 'center', 
      height: '100vh' // Full viewport height
    }}>
      <img src={Loader} alt="Loading..." className="loader-image"/>
    </div>
  );
};

export default LoaderGif;
