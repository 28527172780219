// import React, { useState } from "react";
// import Button from "@mui/material/Button";
// import ButtonGroup from "@mui/material/ButtonGroup";
// import { useStyles } from "./style.js";
// import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
// import { db } from "../db.js";
// import { useLiveQuery } from "dexie-react-hooks";
// import { Dialog, DialogContent, DialogTitle } from "@mui/material";

// const ExpandButton = (props) => {

//   const [openAlert, setOpenAlert] = useState(false);
//   const classes = useStyles();
//   const [state, setState] = React.useState({
//     quantity: 0,
//     productCount: 0,
//     counter: false,
//     cartData: [],
//     itemfound: false,
//   });
//   const [open, setOpen] = React.useState(false);
//   const { cart } = db;
//   const cartdata = useLiveQuery(() => cart.toArray(), [cart]);

//   const handleClose = () => {
//     setOpen(false);
//     setOpenAlert(false);
//   };

//   const removestorefromcart = async (storeid) => {

//     await db.cart.delete(storeid);
//     setOpenAlert(false);
//   };

//   const handleStoreClose = () => {
//     setOpenAlert(false)
//   }

//   // create cart
//   const calCulateStoretotal = (products) => {
//     let t = 0;
//     products.map(
//       (p) => (t = t + Number(p.cartquantity) * Number(p.productprice))
//     );
//     return t;
//   };
// const handleAdd = async (
//   productID,
//   productName,
//   productImage,
//   price,
//   promoprice,
//   storeID, // storeID is an object
//   storeName,
//   uom,
//   productquantity,
//   q
// ) => {
//   // Extract the id from storeID object
//   console.log("productCount productCount ",state?.productCount,q )
//   setState({ productCount: Number(state.productCount + q) });
//   const storeKey = typeof storeID === 'object' && storeID.id ? storeID.id : storeID;

//   // Check if storeKey is valid (not undefined or null and of valid type)
//   if (!storeKey || typeof storeKey !== "string") {
//     console.error("Invalid storeID:", storeID);
//     return;
//   }

//   let data, flag = false;
//   let storedata;

//   try {
//     // Get cart data for the store
//     storedata = await db.cart.get(storeKey);

//     // Check if items from other stores exist
//     const itemsFromOtherStore = await db.cart.where("storeid").notEqual(storeKey).count();

//     // If items from other stores exist, show alert and return
//     if (itemsFromOtherStore > 0) {
//       setOpenAlert(true);
//       return;
//     }

//     // Check if storedata is undefined or null
//     if (
//       storedata === undefined ||
//       storedata === "undefined" ||
//       storedata === null ||
//       storedata === "null"
//     ) {
//       // Limit the cart size to 5 items
//       if (cartdata.length >= 5) {
//         props.props.setCartlimit();
//       } else {
//         setState({ productCount: Number(state.productCount + q) });
//         data = {
//           storeid: storeKey, // Use extracted storeKey
//           storename: `${storeName}`,
//           totalPrice: Number(price),
//           products: [
//             {
//               productid: `${productID}`,
//               productQuantity: productquantity,
//               promoprice: promoprice,
//               cartquantity: 1,
//               productname: `${productName}`,
//               image: productImage,
//               productprice: Number(price),
//               uom: uom,
//             },
//           ],
//         };
//         setState({ ...state, productCount: 1, cartData: cartdata });
//       }
//     } else {
//       // Check if the product already exists in the cart
//       storedata.products.forEach((product, index) => {
//         if (product.productid === productID) {
//           flag = true;
//           let filteredProductList = storedata.products.filter(
//             (p) => p.productid !== productID
//           );
//           data = {
//             storeid: storeKey, // Use extracted storeKey
//             storename: `${storeName}`,
//             totalPrice: calCulateStoretotal(filteredProductList),
//             products: [
//               ...filteredProductList.slice(0, index),
//               {
//                 productid: `${productID}`,
//                 productQuantity: productquantity,
//                 promoprice: promoprice,
//                 cartquantity: Number(product.cartquantity) + q,
//                 productname: `${productName}`,
//                 image: productImage,
//                 productprice: Number(price),
//                 uom: uom,
//               },
//               ...filteredProductList.slice(index),
//             ],
//           };
//           setState({
//             ...state,
//             productCount: state.productCount + q,
//             cartData: cartdata,
//           });
//         }
//       });

//       // If product is not found, add it to the cart
//       if (!flag) {
//         data = {
//           storeid: storeKey, // Use extracted storeKey
//           storename: `${storeName}`,
//           totalPrice: calCulateStoretotal(storedata.products),
//           products: [
//             ...storedata.products,
//             {
//               productid: `${productID}`,
//               productQuantity: productquantity,
//               cartquantity: 1,
//               productname: `${productName}`,
//               promoprice: promoprice,
//               image: productImage,
//               productprice: Number(price),
//               uom: uom,
//             },
//           ],
//         };
//       }
//     }

//     // Add or update the cart data in IndexedDB
//     if (
//       storedata === undefined ||
//       storedata === "undefined" ||
//       storedata === null ||
//       storedata === "null"
//     ) {
//       await db.cart.add(data);
//     } else {
//       await db.cart.update(storeKey, data);
//     }

//     // Update state after cart operation
//     setState({ ...state, counter: !state.counter, cartData: cartdata });

//   } catch (err) {
//     console.error("Error in IndexedDB operation:", err);
//   }
// };

//   // remove product from cart

//   const removeStore = async (storeID) => {
//     await db.cart.delete(storeID);
//     setOpen(false);
//   };

//   const handleRemove = async (storeID, productID) => {
//     // let data;
//     let filteredProductList;
//     setState({ ...state, productCount: 0 });
//     let storedata = await db.cart.get(storeID);
//     storedata.products.forEach((product) => {
//       if (product.productid === productID) {
//         filteredProductList = storedata.products.filter(
//           (p) => p.productid !== productID
//         );
//         setState({ ...state, productCount: 0 });
//         setState({ ...state, counter: !state.counter, cartData: cartdata });
//       }
//     });
//     if (filteredProductList.length === 0) await db.cart.delete(storeID);
//     else {
//       await db.cart
//         .where("storeid")
//         .equals(`${storeID}`)
//         .modify({ products: filteredProductList });
//       if (props.application === "cart") {
//         await db.cart.update(db.cart);
//         checkCount(storeID, productID);
//       }
//       setState({ ...state, productCount: 0, cartData: db.cart });
//       setState({ ...state, counter: !state.counter, cartData: cartdata });
//     }
//     setState({ ...state, productCount: 0, cartData: db.cart });
//   };

//   // check the product count in cart

//   const checkCount = async (storeid, id) => {
//     return await db.cart.get(storeid).then((q) => {
//       if (q === undefined || q === "undefined" || q === null || q === "null") {
//         setState({ ...state, productCount: 0 });
//       } else {
//         if (q.products.filter((p) => p.productid === id).length === 0) {
//           setState({ ...state, productCount: 0 });
//         }
//         q.products.forEach((product) => {
//           if (product.productid === id) {
//             setState({
//               ...state,
//               productCount: product.cartquantity,
//               cartData: cartdata,
//             });
//           }
//         });
//         // if(state.itemfound===false)
//         // setState({ ...state, productCount: 0 });
//       }
//     });
//   };

//   React.useEffect(
//     () => {
//       checkCount(props.storeid, props.id);
//     },
//     // eslint-disable-next-line
//     [cartdata]
//   );
//   React.useEffect(
//     () => {
//       checkCount(props.storeid, props.id);
//     },
//     // eslint-disable-next-line
//     [state.counter, state.productCount, state.cartData, cartdata]
//   );
//   if (!cartdata || cartdata === undefined) return null;

//   return (
//     <React.Fragment>

//       {props?.props?.StoreDetails?.storeWorking === true && (
//         state.productCount === 0 ? (
//           <div
//             className={classes.addtocartbutton}
//             onClick={() => {
//               handleAdd(
//                 props.id,
//                 props.productname,
//                 props.image,
//                 props.markedupProductPrice,
//                 props.promoprice,
//                 props.storeid,
//                 props.storename,
//                 props.uom,
//                 props.quantity,
//                 1
//               );
//             }}
//           >
//             {"Add to cart"}
//           </div>
//         ) : (
//           <ButtonGroup
//             variant="text"
//             style={{
//               width: "100%",
//               height: "34px",
//               userSelect: "none",
//               borderRadius: "14px",
//               backgroundColor: "#EAF6E1",
//               border: "none",
//               fontSize: "12px",
//               justifyContent: "center",
//               alignItems: "center",
//               display: "flex",
//             }}
//             aria-label="outlined button group"
//           >
//             {state.productCount === 1 ? (
//               <Button
//                 style={{
//                   background: "#FFFFFF",
//                   border: "1px solid rgba(0, 0, 0, 0.03)",
//                   width: "10%",
//                   height: "34px",
//                   borderRadius: "14px",
//                   color: "#85BE49",
//                   fontSize: "9px",
//                 }}
//                 onClick={() => {
//                   setState({ ...state, productCount: 0 });
//                   handleRemove(props.storeid, props.id);
//                 }}
//               >
//                 <DeleteOutlineIcon />
//               </Button>
//             ) : state.productCount === 0 || state.productCount < 1 ? (
//               <Button
//                 style={{
//                   background: " #FFFFFF",
//                   border: "1px solid rgba(0, 0, 0, 0.03)",
//                   width: "10%",
//                   height: "34px",
//                   borderRadius: "14px",
//                   color: "#000000",
//                   userSelect: "none",
//                 }}
//                 disabled
//               >
//                 -
//               </Button>
//             ) : (
//               <Button
//                 style={{
//                   background: " #FFFFFF",
//                   border: "1px solid rgba(0, 0, 0, 0.03)",
//                   width: "10%",
//                   height: "34px",
//                   borderRadius: "14px",
//                   color: "#000000",
//                   userSelect: "none",
//                 }}
//                 onClick={() => {
//                   handleAdd(
//                     props.id,
//                     props.productname,
//                     props.image,
//                     props.markedupProductPrice,
//                     props.promoprice   ,
//                     props.storeid,
//                     props.storename,
//                     props.uom,
//                     props.quantity,
//                     -1
//                   );
//                   console.log("propsprops",props)
//                 }}
//               >
//                 -
//               </Button>
//             )}
//             <Button
//               style={{
//                 border: "none",
//                 color: "#000000",
//                 fontSize: "12px",
//                 width: "80%",
//                 userSelect: "none",
//               }}
//             >
//               {state.productCount}
//             </Button>
//             <Button
//               disabled={state.productCount > 49 ? true : false}
//               style={
//                 state.productCount > 49
//                   ? {
//                     background: "rgba(196, 196, 196)",
//                     border: "1px solid rgba(196, 196, 196, 0.2)",
//                     width: "10%",
//                     height: "34px",
//                     borderRadius: "14px",
//                     color: "#ffffff",
//                     userSelect: "none",
//                   }
//                   : {
//                     background: "#85BE49",
//                     border: "1px solid rgba(0, 0, 0, 0.03)",
//                     width: "10%",
//                     height: "34px",
//                     borderRadius: "14px",
//                     color: "#ffffff",
//                     userSelect: "none",
//                   }
//               }
//               onClick={() => {
//                 handleAdd(
//                   props.id,
//                   props.productname,
//                   props.image,
//                   props.markedupProductPrice ,
//                   ( (   (
//                     (
//                    (parseFloat(props.price) - parseFloat(props.promoprice)) /
//                parseFloat(props.price) *
//                100)* parseFloat(props?.markedupProductPrice))/100 )+ parseFloat(props?.markedupProductPrice))?.toFixed(2) ,
//                   props.storeid,
//                   props.storename,
//                   props.uom,
//                   props.quantity,
//                   1
//                 );
//               }}
//             >
//               +
//             </Button>
//           </ButtonGroup>
//         )
//       )}

//       <Dialog
//         fullWidth={true}
//         maxWidth={"sm"}
//         open={open}
//         onClose={handleClose}
//         PaperProps={{ style: { borderRadius: "18px", padding: "30px" } }}
//       >
//         <DialogTitle
//           style={{ color: "#000", fontWeight: 700, fontSize: "20px" }}
//         >
//           Replace Cart item?
//         </DialogTitle>
//         <DialogContent>
//           <div
//             style={{
//               width: "100%",
//               display: "flex",
//               justifyContent: "center",
//               textAlign: "left",
//               alignItems: "center",
//               paddingTop: "15px",
//               color: "#7F7F7F",
//               fontSize: "16px",
//               fontWeight: 400,
//             }}
//           >
//             {`Your cart contains items from ${cartdata &&
//               cartdata !== null &&
//               cartdata[0] &&
//               cartdata[0].storename
//               } Do you want to discard the selection and add items from ${props.storename}?`}
//           </div>
//           <div
//             style={{
//               width: "100%",
//               display: "flex",
//               justifyContent: "center",
//               textAlign: "center",
//               alignItems: "center",
//               paddingTop: "35px",
//             }}
//           >
//             <button
//               onClick={() => setOpen(false)}
//               style={{
//                 cursor: "pointer",
//                 fontSize: "18px",
//                 fontWeight: 700,
//                 border: "none",
//                 width: "40%",
//                 padding: "15px 40px",
//                 borderRadius: "30px",
//                 display: "flex",
//                 justifyContent: "center",
//                 textAlign: "center",
//                 alignItems: "center",
//                 color: "#000",
//                 background: "rgba(239, 241, 237, 1)",
//               }}
//             >
//               {"No"}
//             </button>
//             &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
//             <button
//               onClick={() =>
//                 removeStore(
//                   cartdata &&
//                   cartdata !== null &&
//                   cartdata[0] &&
//                   cartdata[0].storeid
//                 )
//               }
//               style={{
//                 cursor: "pointer",
//                 fontSize: "18px",
//                 fontWeight: 700,
//                 border: "none",
//                 width: "40%",
//                 padding: "15px 40px",
//                 borderRadius: "30px",
//                 display: "flex",
//                 justifyContent: "center",
//                 textAlign: "center",
//                 alignItems: "center",
//                 color: "#fff",
//                 background: "rgba(106, 169, 42, 1)",
//               }}
//             >
//               {"Yes"}
//             </button>
//           </div>
//         </DialogContent>
//       </Dialog>
//       {openAlert && (
//         <Dialog
//           PaperProps={{
//             style: {
//               borderRadius: "13px",
//               backgroundColor: "#ffffff",
//               width: "450px",
//               padding: "25px",
//             },
//           }}
//           maxWidth={"md"}
//           open={openAlert}
//           aria-labelledby="responsive-dialog-title"
//         >
//           <DialogTitle
//             style={{
//               display: "flex",
//               flexDirection: "column",
//               justifyContent: "center",
//               alignItems: "center",
//               textAlign: "center",
//             }}
//           >
//             {`Replace Cart Item?`}
//           </DialogTitle>
//           <DialogContent
//             style={{
//               justifyContent: "center",
//               alignItems: "center",
//               textAlign: "center",
//               display: "flex",
//             }}
//           >
//             {cartdata.map((cartItem) => (
//               <div style={{ display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column" }}>
//                 Your cart contains items from {cartItem.storename} do you want to discard the selection and add items from {props?.storename}?

//                 <div
//                   style={{
//                     width: "80%",
//                     marginTop: "15px",
//                     justifyContent: "center",
//                     alignItems: "center",
//                     textAlign: "center",
//                     paddingTop: "8px",
//                     display: "flex",
//                     gap: "30px"
//                   }}
//                 >

//                   <button style={{ padding: "10px 50px", background: "#e0dcdc", borderRadius: "10px", border: "transparent", fontWeight: "600", cursor: "pointer" }} onClick={handleStoreClose}>
//                     {"No"}
//                   </button>

//                   <button
//                     key={cartItem.storeid} // Adding a key prop for list rendering
//                     style={{ padding: "10px 50px", background: "#85BE49", borderRadius: "10px", color: "white", border: "transparent", fontWeight: "600", cursor: "pointer" }}
//                     onClick={() => removestorefromcart(cartItem.storeid)}
//                   >
//                     {"Yes"}
//                   </button>

//                 </div>
//               </div>))}
//           </DialogContent>
//         </Dialog>

//       )}
//     </React.Fragment>
//   );
// };
// export default ExpandButton;

import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import { useStyles } from "./style.js";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { db } from "../db.js";
import { useLiveQuery } from "dexie-react-hooks";
import { Dialog, DialogContent, DialogTitle,useMediaQuery } from "@mui/material";
import { useTheme } from '@mui/material/styles';

const ExpandButton = (props) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const [openAlert, setOpenAlert] = useState(false);
  const classes = useStyles();
  const [state, setState] = React.useState({
    quantity: 0,
    productCount: 0,
    counter: false,
    cartData: [],
  });
  const [open, setOpen] = React.useState(false);
  const { cart } = db;
  const cartdata = useLiveQuery(() => cart.toArray(), [cart]);

  useEffect(() => {
    // Loop through cartData to find the product
    const existingProduct = cartdata
      ?.flatMap((store) => store?.products) // Flatten the products array
      .find((product) => product?.productid === props?.id); // Find the product by ID

    if (existingProduct) {
      setState({ productCount: existingProduct?.cartquantity }); // Set count to cartquantity if it exists
    }
  }, [props?.id, cartdata]);

  const handleClose = () => {
    setOpen(false);
    setOpenAlert(false);
  };

  const removestorefromcart = async (storeid) => {
    await db.cart.delete(storeid);
    setOpenAlert(false);
    handleAdd(
      props.id,
      props.productname,
      props.image,
      props.markedupProductPrice,
      props.promoprice,
      props.storeid,
      props.storename,
      props.uom,
      props.quantity,
      1
    );
  };

  const handleStoreClose = () => {
    setOpenAlert(false);
  };

  const calCulateStoretotal = (products) => {
    // Calculate the total price based on the product list
    return products.reduce(
      (total, product) => total + product.productprice * product.cartquantity,
      0
    );
  };

  const handleAdd = async (
    productID,
    productName,
    productImage,
    price,
    promoprice,
    storeID,
    storeName,
    uom,
    productquantity,
    q
  ) => {
    const storeKey =
      typeof storeID === "object" && storeID.id ? storeID.id : storeID;

    if (!storeKey || typeof storeKey !== "string") {
      console.error("Invalid storeID:", storeID);
      return;
    }

    let data,
      flag = false;
    let storedata;

    try {
      storedata = await db.cart.get(storeKey);
      const itemsFromOtherStore = await db.cart
        .where("storeid")
        .notEqual(storeKey)
        .count();

      if (itemsFromOtherStore > 0) {
        setOpenAlert(true);
        return;
      }

      if (!storedata) {
        if (cartdata.length >= 5) {
          props.props.setCartlimit();
        } else {
          data = {
            storeid: storeKey,
            storename: `${storeName}`,
            totalPrice: Number(price),
            products: [
              {
                productid: `${productID}`,
                productQuantity: productquantity,
                promoprice: promoprice,
                cartquantity: 1,
                productname: `${productName}`,
                image: productImage,
                productprice: Number(price),
                uom: uom,
              },
            ],
          };
          await db.cart.add(data);
        }
      } else {
        storedata.products.forEach((product, index) => {
          if (product.productid === productID) {
            flag = true;
            let filteredProductList = storedata.products.filter(
              (p) => p.productid !== productID
            );
            data = {
              storeid: storeKey,
              storename: `${storeName}`,
              totalPrice: calCulateStoretotal(filteredProductList), // Ensure this is defined
              products: [
                ...filteredProductList.slice(0, index),
                {
                  productid: `${productID}`,
                  productQuantity: productquantity,
                  promoprice: promoprice,
                  cartquantity: Number(product.cartquantity) + q,
                  productname: `${productName}`,
                  image: productImage,
                  productprice: Number(price),
                  uom: uom,
                },
                ...filteredProductList.slice(index),
              ],
            };
          }
        });

        if (!flag) {
          data = {
            storeid: storeKey,
            storename: `${storeName}`,
            totalPrice: calCulateStoretotal(storedata.products), // Ensure this is defined
            products: [
              ...storedata.products,
              {
                productid: `${productID}`,
                productQuantity: productquantity,
                cartquantity: 1,
                productname: `${productName}`,
                promoprice: promoprice,
                image: productImage,
                productprice: Number(price),
                uom: uom,
              },
            ],
          };
        }

        await db.cart.update(storeKey, data);
      }

      setState((prevState) => ({
        ...prevState,
        productCount: prevState.productCount + q,
      }));
    } catch (err) {
      console.error("Error in IndexedDB operation:", err);
    }
  };

  const handleRemove = async (storeID, productID) => {
    let storedata = await db.cart.get(storeID);
    let filteredProductList = storedata.products.filter(
      (p) => p.productid !== productID
    );

    if (filteredProductList.length === 0) {
      await db.cart.delete(storeID);
    } else {
      await db.cart.update(storeID, { products: filteredProductList });
    }

    setState((prevState) => ({
      ...prevState,
      productCount: 0,
    }));
  };

  const checkCount = async (storeid, id) => {
    const storedata = await db.cart.get(storeid);
    if (storedata) {
      const product = storedata.products.find((p) => p.productid === id);
      setState((prevState) => ({
        ...prevState,
        productCount: product ? product.cartquantity : 0,
      }));
    } else {
      setState((prevState) => ({
        ...prevState,
        productCount: 0,
      }));
    }
  };

  React.useEffect(() => {
    checkCount(props.storeid, props.id);
  }, [cartdata]);

  if (!cartdata || cartdata === undefined) return null;

  return (
    <React.Fragment>
      {props?.props?.StoreDetails?.storeWorking === true &&
        (state.productCount === 0 ? (
          <div
            className={classes.addtocartbutton}
            onClick={() => {
              handleAdd(
                props.id,
                props.productname,
                props.image,
                props.markedupProductPrice,
                props.promoprice,
                props.storeid,
                props.storename,
                props.uom,
                props.quantity,
                1
              );
            }}
          >
            {"Add to cart"}
          </div>
        ) : (
          <ButtonGroup
            variant="text"
            style={{
              width: "100%",
              height: "34px",
              userSelect: "none",
              borderRadius: "14px",
              backgroundColor: "#EAF6E1",
              border: "none",
              fontSize: "12px",
              justifyContent: "center",
              alignItems: "center",
              display: "flex",
            }}
            aria-label="outlined button group"
          >
            {state.productCount === 1 ? (
              <Button
                style={{
                  background: "#FFFFFF",
                  border: "1px solid rgba(0, 0, 0, 0.03)",
                  width: "10%",
                  height: "34px",
                  borderRadius: "14px",
                  color: "#85BE49",
                  fontSize: "9px",
                }}
                onClick={() => {
                  handleRemove(props.storeid, props.id);
                }}
              >
                <DeleteOutlineIcon />
              </Button>
            ) : (
              <Button
                style={{
                  background: " #FFFFFF",
                  border: "1px solid rgba(0, 0, 0, 0.03)",
                  width: "10%",
                  height: "34px",
                  borderRadius: "14px",
                  color: "#000000",
                  userSelect: "none",
                }}
                onClick={() => {
                  handleAdd(
                    props.id,
                    props.productname,
                    props.image,
                    props.markedupProductPrice,
                    props.promoprice,
                    props.storeid,
                    props.storename,
                    props.uom,
                    props.quantity,
                    -1
                  );
                }}
              >
                -
              </Button>
            )}
            <Button
              style={{
                border: "none",
                color: "#000000",
                fontSize: "12px",
                width: "80%",
                userSelect: "none",
              }}
            >
              {state.productCount}
            </Button>
            <Button
              disabled={state?.productCount > 49 ? true : false}
              style={
                state?.productCount > 49
                  ? {
                      background: "rgba(196, 196, 196)",
                      boxShadow: "0px 9px 17px rgba(0, 0, 0, 0.1)",
                      width: "38px",
                      height: "38px",
                      borderRadius: "10px",
                      color: "#ffffff",
                      userSelect: "none",
                    }
                  : {
                      background: "#85BE49",
                      border: "1px solid rgba(0, 0, 0, 0.03)",
                      width: "10%",
                      height: "34px",
                      borderRadius: "14px",
                      color: "#ffffff",
                      userSelect: "none",
                    }
              }
              onClick={() => {
                handleAdd(
                  props.id,
                  props.productname,
                  props.image,
                  props.markedupProductPrice,
                  props.promoprice,
                  props.storeid,
                  props.storename,
                  props.uom,
                  props.quantity,
                  1
                );
              }}
            >
              +
            </Button>
          </ButtonGroup>
        ))}

      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Replace Cart</DialogTitle>
        <DialogContent>
          <div style={{ color: "#000", fontSize: "14px" }}>
            You already have a store in the cart. Do you want to replace it?
          </div>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Button
              variant="outlined"
              onClick={() => removestorefromcart(props?.storeid)}
            >
              Replace
            </Button>
            <Button variant="outlined" onClick={handleClose}>
              Cancel
            </Button>
          </div>
        </DialogContent>
      </Dialog>

      {openAlert && (
        <Dialog
          PaperProps={{
            style: {
              borderRadius: "13px",
              backgroundColor: "#ffffff",
              width: "450px",
              padding: "25px",
            },
          }}
          maxWidth={"md"}
          open={openAlert}
          aria-labelledby="responsive-dialog-title"
        >
          <DialogTitle
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              overflowX: "hidden",
              padding: isSmallScreen ? "10px 15px" : "20px 30px",
            }}
          >
            {`Replace Cart Item?`}
          </DialogTitle>
          <DialogContent
            style={{
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
              display: "flex",
              overflowX: "hidden",
              padding: "0px",
            }}
          >
            {cartdata.map((cartItem) => (
              <div
                className="cart-contains"
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                Your cart contains items from {cartItem.storename} do you want
                to discard the selection and add items from {props?.storename}?
                <div
                  style={{
                    width: "80%",
                    marginTop: "15px",
                    justifyContent: "center",
                    alignItems: "center",
                    textAlign: "center",
                    paddingTop: "8px",
                    display: "flex",
                    gap: "30px",
                    width:"100%"
                  }}
                >
                  <button
                    style={{
                      padding: isSmallScreen ? "10px 15px" : "10px 50px",
                      width:"50%",
                      background: "#e0dcdc",
                      borderRadius: "10px",
                      border: "transparent",
                      fontWeight: "600",
                      cursor: "pointer",
                    }}
                    onClick={handleStoreClose}
                  >
                    {"No"}
                  </button>

                  <button
                    key={cartItem.storeid} // Adding a key prop for list rendering
                    style={{
                      padding: isSmallScreen ? "10px 15px" : "10px 50px",
                      width:"50%",
                      background: "#85BE49",
                      borderRadius: "10px",
                      color: "white",
                      border: "transparent",
                      fontWeight: "600",
                      cursor: "pointer",
                    }}
                    onClick={() => removestorefromcart(cartItem.storeid)}
                  >
                    {"Yes"}
                  </button>
                </div>
              </div>
            ))}
          </DialogContent>
        </Dialog>
      )}
    </React.Fragment>
  );
};

export default ExpandButton;
