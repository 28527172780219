import React from "react";
import {
  Box,
  Collapse,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
} from "@mui/material";
import Tooltip from '@mui/material/Tooltip';
import { ExpandLess, ExpandMore } from "@mui/icons-material";

const Sidebar = (props) => {
  const [open, setOpen] = React.useState(false);
  const [categoryopen, setCategoryOpen] = React.useState(false);
  const [category1open, setCategory1Open] = React.useState(false);

  const setDepartmentId = (id) => {
    localStorage.setItem("departmentid", id);
    props.props.getDepartmentProducts({
      storeid: localStorage.getItem('storeid'),
      departmentid: localStorage.getItem('departmentid')
    });
    props.navigate(`/stores/categories/departmentproducts`);
  };     

  const setNoCategoryDepartmentId = (id) => {
    localStorage.setItem("departmentid", id);
    setOpen(!open);
    props.props.getCategorylist({ storeid: localStorage.getItem('storeid'), categoryid: id, userid: localStorage.getItem('userid') });
    props.navigate(`/stores/categories/category`);
  };

  const setCategoryId = (id) => {
    localStorage.setItem("categoryid", id);
    props.props.getCategoryProducts({
      storeid: localStorage.getItem('storeid'),
      categoryid: localStorage.getItem('categoryid'),
      userid: localStorage.getItem('userid')
    });
    props.navigate(`/stores/categories/categoryproducts`);
  };

  const setNoCategoryId = (id) => {
    localStorage.setItem("categoryid", id);
    setCategoryOpen(!open);
    props.props.getCategory1list({ storeid: localStorage.getItem('storeid'), categoryid: localStorage.getItem("categoryid"), userid: localStorage.getItem('userid') });
    props.navigate(`/stores/categories/category1`);
  };

  const setCategory1Id = (id) => {
    localStorage.setItem("category1id", id);
    props.props.getCategory1Products({
      storeid: localStorage.getItem('storeid'),
      categoryid1: localStorage.getItem('category1id'),
      userid: localStorage.getItem('userid')
    });
    props.navigate(`/stores/categories/category1products`);
  };

  const setNoCategory1Id = (id) => {
    localStorage.setItem("category1id", id);
    setCategory1Open(!open);
    props.props.getCategory2list({ storeid: localStorage.getItem('storeid'), categoryid: id, userid: localStorage.getItem('userid') });
    props.navigate(`/stores/categories/category2`);
  };

  const setCategory2Id = (id) => {
    localStorage.setItem("category2id", id);
    props.props.getCategory2Products({
      storeid: localStorage.getItem('storeid'),
      categoryid2: localStorage.getItem('category2product'),
      userid: localStorage.getItem('userid')
    });
    props.navigate(`/stores/categories/category2products`);
  };

  React.useEffect(() => {
    if (!props.props.isDepartments) {
      if (props.params.storeid)
        props.props.getDepartments(props.params.storeid);
      else
        props.props.getDepartments(localStorage.getItem('storeid'));
    }
  },
    // eslint-disable-next-line
    [])
  return (
    <Box flex={1} p={1} sx={{ display: { xs: "none", sm: "none" ,md:"block"}, overflowY: 'visible', width: '100px' }}>
      <Box >
        <List>
          {props.props.isDepartments && props.props.isDepartments === true &&
            props.props.Departments && props.props.Departments !== null && props.props.Departments.length > 0 &&
            props.props.Departments.map(({ name, id, childCategoryPresent, departmentProducts }) =>
              departmentProducts.items.length > 0 &&
                childCategoryPresent === false ? (
                <ListItem
                  style={{
                    width: '100%',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    display: 'inline-block'
                  }}
                  disablePadding
                  onClick={() => {
                    setDepartmentId(id);
                  }}
                >
                  <ListItemButton component="a">
                    <Tooltip title={name}>
                      <ListItemIcon
                        style={{
                          width: '80%',
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          display: 'inline-block'
                        }}>
                        {name}
                      </ListItemIcon>
                    </Tooltip>
                  </ListItemButton>
                </ListItem>
              ) : (
                departmentProducts.items.length > 0 &&
                <>
                  <ListItem
                    style={{
                      width: '100%',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      whiteSpace: 'nowrap',
                      display: 'inline-block'
                    }}
                    disablePadding onClick={() => {
                      setNoCategoryDepartmentId(id);
                    }}>
                    <ListItemButton component="a">
                      <Tooltip title={name}>
                        <ListItemIcon
                          style={{
                            width: '80%',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                            whiteSpace: 'nowrap',
                            display: 'inline-block'
                          }}>
                          {name}
                        </ListItemIcon>
                      </Tooltip>
                      <div
                        style={{
                          textAlign: 'right',
                          width: '100%',
                          float: 'right',
                          color: 'rgba(0,0,0,0.4)',
                          fontSize: '12px'
                        }}>
                        {open && localStorage.getItem("departmentid") === id ? <ExpandLess /> : <ExpandMore />}
                      </div>
                    </ListItemButton>
                  </ListItem>
                  <Collapse in={open && localStorage.getItem("departmentid") === id} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                      {props.props.isCategories && props.props.isCategories === true &&
                        props.props.Categories && props.props.Categories !== null && props.props.Categories.length > 0 &&
                        props.props.Categories.map(({ name, id, childCategoryPresent, categoryProducts }) =>
                          categoryProducts.items.length > 0 &&
                            childCategoryPresent === false ? (
                            <ListItemButton sx={{ pl: 4 }}
                              onClick={() => {
                                setCategoryId(id);
                              }}>
                              <Tooltip title={name}>
                                <ListItemIcon
                                  style={{
                                    width: '80%',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    display: 'inline-block'
                                  }}>
                                  {name}
                                </ListItemIcon>
                              </Tooltip>
                            </ListItemButton>
                          ) :
                            (
                              categoryProducts.items.length > 0 &&
                              <>
                                <ListItemButton
                                  style={{
                                    width: '100%',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    whiteSpace: 'nowrap',
                                    display: 'inline-block'
                                  }}
                                  sx={{ pl: 4 }}
                                  onClick={() => setNoCategoryId(id)}>
                                  <Tooltip title={name}>
                                    <ListItemIcon
                                      style={{
                                        width: '80%',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'nowrap',
                                        display: 'inline-block'
                                      }}>
                                      {name}
                                    </ListItemIcon>
                                  </Tooltip>
                                  <div
                                    style={{
                                      textAlign: 'right',
                                      width: '100%',
                                      float: 'right',
                                      color: 'rgba(0,0,0,0.4)',
                                      fontSize: '12px'
                                    }}>
                                    {categoryopen && localStorage.getItem("categoryid") === id ? <ExpandLess /> : <ExpandMore />}
                                  </div>
                                </ListItemButton>
                                <Collapse in={categoryopen && localStorage.getItem("categoryid") === id} timeout="auto" unmountOnExit>
                                  <List component="div" disablePadding>
                                    {props.isCategories1 && props.isCategories1 === true &&
                                      props.Categories1 && props.Categories1 !== null && props.Categories1.length > 0 &&
                                      props.Categories1.map(({ name, id, childCategoryPresent, category1Products }) =>
                                        category1Products.items.length > 0 &&
                                          childCategoryPresent === false ? (
                                          <ListItemButton
                                            style={{
                                              width: '100%',
                                              overflow: 'hidden',
                                              textOverflow: 'ellipsis',
                                              whiteSpace: 'nowrap'
                                            }}
                                            sx={{ pl: 4 }}
                                            onClick={() => {
                                              setCategory1Id(id);
                                            }}>
                                            <Tooltip title={name}>
                                              <ListItemIcon
                                                style={{
                                                  width: '80%',
                                                  overflow: 'hidden',
                                                  textOverflow: 'ellipsis',
                                                  whiteSpace: 'nowrap',
                                                  display: 'inline-block'
                                                }}>
                                                {name}
                                              </ListItemIcon>
                                            </Tooltip>
                                          </ListItemButton>
                                        ) :
                                          (
                                            category1Products.items.length > 0 &&
                                            <Collapse in={categoryopen && localStorage.getItem("categoryid") === id} timeout="auto" unmountOnExit>
                                              <List component="div" disablePadding>
                                                {props.isCategories2 && props.isCategories2 === true &&
                                                  props.Categories2 && props.Categories2 !== null && props.Categories2.length > 0 &&
                                                  props.Categories2.map(({ name, id, childCategoryPresent, category2Products }) =>
                                                    category2Products.items.length > 0 &&
                                                      childCategoryPresent === false ?
                                                      <ListItemButton
                                                        style={{
                                                          width: '100%',
                                                          overflow: 'hidden',
                                                          textOverflow: 'ellipsis',
                                                          whiteSpace: 'nowrap',
                                                          display: 'inline-block'
                                                        }}
                                                        sx={{ pl: 4, overflow: 'hidden', textOverflow: 'ellipsiss' }}
                                                        onClick={() => setCategory2Id(id)}>
                                                        <Tooltip title={name}>
                                                          <ListItemIcon
                                                            style={{
                                                              width: '80%',
                                                              overflow: 'hidden',
                                                              textOverflow: 'ellipsis',
                                                              whiteSpace: 'nowrap',
                                                              display: 'inline-block'
                                                            }}>
                                                            {name}
                                                          </ListItemIcon>
                                                        </Tooltip>
                                                      </ListItemButton>
                                                      :
                                                      category2Products.items.length > 0 &&
                                                      <ListItemButton
                                                        style={{
                                                          width: '100%',
                                                          overflow: 'hidden',
                                                          textOverflow: 'ellipsis',
                                                          whiteSpace: 'nowrap',
                                                          display: 'inline-block'
                                                        }}
                                                        sx={{ pl: 4, overflow: 'hidden', textOverflow: 'ellipsiss' }}
                                                        onClick={() => setNoCategory1Id(id)}>
                                                        <Tooltip title={name}>
                                                          <ListItemIcon
                                                            style={{
                                                              width: '80%',
                                                              overflow: 'hidden',
                                                              textOverflow: 'ellipsis',
                                                              whiteSpace: 'nowrap',
                                                              display: 'inline-block'
                                                            }}>
                                                            {name}
                                                          </ListItemIcon>
                                                        </Tooltip>
                                                        <div
                                                          style={{
                                                            textAlign: 'right',
                                                            width: '100%',
                                                            float: 'right',
                                                            color: 'rgba(0,0,0,0.4)',
                                                            fontSize: '12px'
                                                          }}>
                                                          {category1open && localStorage.getItem("category1id") === id ? <ExpandLess /> : <ExpandMore />}
                                                        </div>
                                                      </ListItemButton>
                                                  )}
                                              </List>
                                            </Collapse>)
                                      )}
                                  </List>
                                </Collapse>
                              </>)
                        )}
                    </List>
                  </Collapse>
                </>
              )
            )}
        </List>
      </Box>
    </Box>
  );
};

export default Sidebar;