import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        backgroundColor: 'rgba(234, 246, 225, 1)',
        borderTopRightRadius: '120px',
        padding: '40px 0px',
        color: 'rgba(0, 0, 0, 0.4)',
        justifyContent: 'center',
        alignItems: 'center',
        '@media (max-width: 768px)': {
          padding: '70px',
          justifyContent:"left"
      },
        '@media (max-width: 426px)': {
          padding: '30px',
          justifyContent:"left"
      },
        '@media (max-width: 376px)': {
          padding: '20px',
          justifyContent:"left"
      },
    },
    col: {
        position: 'absolute',
        zIndex: 2,
        top: -315,
        right: 0,
        left: 0,
    },
    getapp: {
        maxWidth: '100%',
        maxHeight: '100%',
        width: '700px',
        height: '700px',
        position: 'relative',
        zIndex: 1,
    },
    appStoreIcon:{
        '&:hover':{
            width: "140px",
            height: "50px"
        }
    },
    heading2: {
        paddingTop: '23px'
    },
    heading4: {
        paddingTop: '23px',
        paddingBottom: '20px',

    },
}));