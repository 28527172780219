// import React from 'react';
// import SearchIcon from '@mui/icons-material/Search';
// import './index.css';

// const Storesearch = (props) => {
//     const [value, setValue] = React.useState('');
//     const [inputclicked,setInputClicked] = React.useState(false);
//     React.useEffect(() => {
//         let SearchResult = document.getElementById("SearchResults");
//         SearchResult.style.display = "none"
//         document.onclick = function (event) {
//             if (event.target.id !== "SearchResults") {
//                 SearchResult.style.display = "block";
//             }
//             if (event.target.id !== "SearchInput") {
//                 SearchResult.style.display = "none";
//             }
//         }
//     }, [])

//     return (
//         <div className='search-container'>
//             <div className='TopHeader'>
//                 <div className='InputBox'>
//                     <div className='searchIcon'>
//                         <SearchIcon />
//                     </div>
//                     <input type="search" placeholder='Search Stores...' id="SearchInput"
//                         value={value}
//                         onClick={()=>setInputClicked(false)}
//                         onChange={(e) => {
//                             setValue(e.target.value)
//                         }} />
//                     <ul className='SearchResults' id="SearchResults">
//                         {
//                            ( (inputclicked===true&&value==='')&&
//                             props.isStoresSuccess && props.isStoresSuccess === true && props.Stores && props.Stores !== null &&
//                             props.Stores.length > 0 )?
//                             props.Stores.map((store, index) =>
//                                 <li
//                                     onClick={(e) => {
//                                         e.preventDefault();
//                                         props.getDepartments(store.id);
//                                         localStorage.setItem("storeLogo", store.storeLogoUrl);
//                                         localStorage.setItem("storeName", store.storeName);
//                                         localStorage.setItem('storeid', store.id);
//                                         props.navigate('/stores/categories');
//                                         setValue('');
//                                     }}
//                                 >
//                                     <span className='searchresulticon'> <SearchIcon /></span>
//                                     <span className='searchresultstext'>
//                                         {store.storeName}
//                                     </span>
//                                 </li>
//                             )
//                             :
//                             value &&
//                             props.isStoresSuccess && props.isStoresSuccess === true && props.Stores && props.Stores !== null &&
//                             props.Stores.length > 0 &&
//                             props.Stores.map((store, index) =>
//                                 (store.storeName.toLowerCase()
//                                     .startsWith(value.toLowerCase())) &&
//                                 <li
//                                     onClick={(e) => {
//                                         e.preventDefault();
//                                         props.getDepartments(store.id);
//                                         localStorage.setItem("storeLogo", store.storeLogoUrl);
//                                         localStorage.setItem("storeName", store.storeName);
//                                         localStorage.setItem('storeid', store.id);
//                                         props.navigate('/stores/categories');
//                                         setValue('');
//                                     }}
//                                 >
//                                     <span className='searchresulticon'> <SearchIcon /></span>
//                                     <span className='searchresultstext'>
//                                         {store.storeName}
//                                     </span>
//                                 </li>
//                             )
//                         }
//                     </ul>
//                 </div>
//             </div>
//         </div>
//     )
// }

// export default Storesearch 
 //-----------------------------------------------------------//
//Additional Code 

import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import './index.css';

const Storesearch = (props) => {
    const [value, setValue] = React.useState('');
    // const [inputclicked, setInputClicked] = React.useState(false);
    // const [showResults, setShowResults] = React.useState(false);

    // React.useEffect(() => {
    //     let SearchResult = document.getElementById("SearchResults");

    //     document.onclick = function (event) {
    //         if (event.target.id !== "SearchInput") {
    //             setShowResults(false);
    //         }
    //     };
    // }, []);

    // const handleInputChange = (e) => {
    //     setValue(e.target.value);
    //     setShowResults(e.target.value.trim().length > 0);
    // };

    return (
        <div className='search-container'>
            <div className='TopHeader'>
                <div className='InputBox'>
                    <div className='searchIcon'>
                        <SearchIcon />
                    </div>
                    <input
                        type="search"
                        placeholder='Search here'
                        id="SearchInput"
                        value={value}
                        onClick={(e) => {
                            e.persist();
                            props.navigate("/Search")
                            // setInputClicked(false)
                        }}
                        // onChange={handleInputChange}
                    />
                 
                </div>
            </div>
        </div>
    );
};

export default Storesearch;

