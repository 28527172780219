import React from "react";
//import defaultImage from "../../Assets/Images/defaultImage.svg"
import { useMediaQuery } from "react-responsive";
const GlobalSearchResults = ({
  searchProductsGlobalList,
  onProductClick,
  filteredStores,
  props,
}) => {
  const isMobileScreen = useMediaQuery({ query: "(max-width: 430px)" });
  const storeRoute = (id, storeLogo, Storename, storeStatus) => {
    props.getDepartments(id);
    localStorage.setItem("storeLogo", storeLogo);
    localStorage.setItem("storeid", id);
    localStorage.setItem("storeName", Storename);
    localStorage.setItem("businessType", props.businessType);
    localStorage.setItem("storelocation", props.storelocation);
    props.navigate(`/stores/categories`);
  };
   console.log("searchProductsGlobalList",searchProductsGlobalList)
  if (!searchProductsGlobalList.length && !filteredStores.length) {
    return <div>No results found.</div>;
  }

  if (!searchProductsGlobalList.length) {
    return (
      <div >
        {filteredStores.map((store) => (
          <div
            className="product"
            key={store.id}
            onClick={(e) =>
              storeRoute(store?.id, store?.storeLogoUrl, store.storeName)
            }
          >
            <div className="popular_prdt_child_div">
              <div
                style={{
                  width: "10%",
                }}
              >
                <img
                  src={store.storeLogoUrl }
                  alt="Product"
                  style={{
                    width: "100%",
                    height: "100%",
                  }}
                />
              </div>
              <div>
                <p>{store.storeName}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }

  if (!filteredStores.length) {
    return (
      <div>
        {searchProductsGlobalList.map((product) => (
          <div
            className="product"
            key={product._id}
            onClick={() => handleClick(product._id)}
          >
            {/* Render  product */}
            <div className="popular_prdt_child_div">
              <div
                style={{  
                  width: "10%",
                }}
              >
                <img
                  src={ product.image[0]} 
                  
                  //alt="Product"
                  style={{
                    height: "60px",
                    objectFit:'contain'
                  }}
                />
              </div>
              <div>
                <p>{product._id}</p>
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  }

 
  return (
    <div >
      {searchProductsGlobalList.map((product) => (
        <div
          className="product"
          key={product._id}
          onClick={() => handleClick(product._id)}
        >
          {/* Render  product */}
          <div className="popular_prdt_child_div">
            <div
              style={{
                width: "10%",
              }}
            >
              <img
                 src={product.image ? product.image[0] : product.image[0]} 
                
                style={{
                  height: "60px",
                  objectFit:'contain'
                }}
              />
            </div>
            <div>
              <p>{product._id}</p>
            </div>
          </div>
        </div>
      ))}
      {filteredStores.map((store) => (
        <div className="store" key={store.id} onClick={()=>storeRoute(store.id,store.storeLogoUrl,store.businessType,)}>
          {/* Render store */}

          <div className="popular_prdt_child_div">
            <div
              style={{
                width: "10%",
              }}
            >
              <img
                src={store.storeLogoUrl}
                alt="Product"
                style={{
                  height: "60px",
                  objectFit:'contain'
                }}
              />
            </div>
            <div>
              <p>{store.storeName}</p>
            </div>
          </div>
        </div>
      ))}
    </div>
  );

  function handleClick(productName) {
    onProductClick(productName);
    props?.updateRecentSearch(productName);
  }
};

export default GlobalSearchResults;
