// import React from 'react';
// import SearchIcon from '@mui/icons-material/Search';
// import errorproduct from '../../Assets/Images/errorproduct.svg';
// import { Dialog, DialogContent, DialogTitle } from '@mui/material';
// import CloseIcon from '@mui/icons-material/Close';
// import { useMediaQuery, useTheme } from '@mui/material';
// import ProductDetails from '../ProductDetails';
// import './index.css';

// const ProductSearch = (props) => {
//     const [value, setValue] = React.useState('');
//     const [open, setOpen] = React.useState(false);
//     const theme = useTheme();
//     const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
//     const handleInputChange = (v) => {
//         setValue(v);
//         if (value.length >= 2) {
//             props.searchproducts({
//                 searchkey: value,
//                 storeid: localStorage.getItem('storeid'),
//                 userid: localStorage.getItem('userid')
//             })
//         }
//     };
//     const handleClose = () => {
//         setOpen(false);
//     }
//     React.useEffect(() => {
//         let SearchResult = document.getElementById("SearchResults");
//         SearchResult.style.display = "none"
//         document.onclick = function (event) {
//             if (event.target.id !== "SearchResults") {
//                 props.clearProductSearchProps();
//                 SearchResult.style.display = "block";
//             }
//             if (event.target.id !== "SearchInput") {
//                 SearchResult.style.display = "none";
//             }
//         }
//     }, [])

//     return (
//         <>
//             <div className='search-container'>
//                 <div className='TopHeader'>
//                     <div className='InputBox'>
//                         <div className='searchIcon'>
//                             <SearchIcon />
//                         </div>
//                         <input type="search" placeholder={`Search ${localStorage.getItem('storeName') && localStorage.getItem('storeName')}`} id="SearchInput"
//                             value={value}
//                             onChange={(e) => handleInputChange(e.target.value)} />
//                         <ul className='SearchResults' id="SearchResults">
//                             {
//                                 props.isSearchingProducts && props.isSearchingProducts === true ?
//                                     'Loading...' :
//                                     props.isSearchProducts && props.isSearchProducts === true &&
//                                         props.SearchProducts && props.SearchProducts !== null &&
//                                         props.SearchProducts.length > 0
//                                         ?
//                                         props.SearchProducts.map((product, index) =>
//                                             <li onClick={() => {
//                                                 localStorage.setItem('productid', product._id);
//                                                 props.getProductDetails({
//                                                     userid: localStorage.getItem('userid'),
//                                                     storeid: localStorage.getItem('productstoreid') ? localStorage.getItem('productstoreid') : localStorage.getItem('storeid'),
//                                                     id: product._id
//                                                 })
//                                                 setOpen(true);
//                                             }}>
//                                                 <span className='searchresulticon'> <SearchIcon /></span>
//                                                 <span style={{ float: 'left', paddingLeft: '15px', paddingRight: '20px' }}>
//                                                     {product._source && product._source.image &&
//                                                         product._source.image.primary &&
//                                                         (product._source.image.primary === null ||
//                                                             product._source.image.primary === 'null' ||
//                                                             product._source.image.primary === undefined ||
//                                                             product._source.image.primary === 'undefined'
//                                                         ) ?
//                                                         <img src={errorproduct} alt="product" className='searchimage' />
//                                                         :
//                                                         <img src={product._source.image.primary && product._source.image.primary}
//                                                             className='searchimage'
//                                                             alt="product" />
//                                                     }
//                                                 </span>
//                                                 <span style={{
//                                                     float: 'right', fontWeight: 600,
//                                                     fontSize: '12px',
//                                                     lineHeight: '19px',
//                                                     fontFamily: 'Nunito',
//                                                     display: "flex",
//                                                     flexDirection: 'column',
//                                                     justifyContent: 'center',
//                                                     alignItems: 'center',
//                                                     textAlign: 'center',
//                                                 }}>
//                                                     {product._source.productname && product._source.productname}
//                                                 </span>
//                                             </li>
//                                         )
//                                         : ('No Search Results')
//                             }
//                         </ul>
//                     </div>
//                 </div>
//             </div>
//             <Dialog
//                 PaperProps={{
//                     style: {
//                         borderRadius: '10px',
//                         backgroundColor: '#ffffff',
//                         width: '100%',
//                         height: '80%',
//                     }
//                 }}
//                 fullScreen={fullScreen}
//                 maxWidth={'md'}
//                 scroll={'paper'}
//                 open={open}
//                 onClose={handleClose}
//                 aria-labelledby="responsive-dialog-title"
//             >
//                 <DialogTitle>
//                     <span
//                         style={{
//                             width: '25px', float: 'right',
//                             color: '#ffffff',
//                             borderRadius: '50%',
//                             fontSize: '14px',
//                             height: '25px', background: '#BDBDBD', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center'
//                         }}>
//                         <CloseIcon
//                             style={{
//                                 cursor: 'pointer',
//                                 fontSize: '14px'
//                             }} onClick={handleClose} />
//                     </span>
//                 </DialogTitle>
//                 <DialogContent>
//                     <ProductDetails
//                     storeoffline={false}
//                         props={props}
//                      />
//                 </DialogContent>
//             </Dialog>
//         </>
//     )
// }

// export default ProductSearch


//new code 
import React from 'react';
import SearchIcon from '@mui/icons-material/Search';
import errorproduct from '../../Assets/Images/errorproduct.svg';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import { useMediaQuery, useTheme } from '@mui/material';
import ProductDetails from '../ProductDetails';
import './index.css';
import Loader from  '../LoaderGif';

const ProductSearch = (props) => {
    const [value, setValue] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const [showNoResults, setShowNoResults] = React.useState(false);
    const [showDropdown, setShowDropdown] = React.useState(false);
    const [searching, setSearching] = React.useState(false); // New state to track if a search is in progress
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

    const handleInputChange = (v) => {
        setValue(v);
        setShowDropdown(true);
        if (v.length >= 2) {
            setShowNoResults(false);
            setSearching(true); // Set searching to true when initiating a search
            props.searchproducts({
                searchkey: v,
                storeid: localStorage.getItem('storeid'),
                userid: localStorage.getItem('userid')
            });
        } else {
            props.clearProductSearchProps();
            setShowNoResults(false);
            setSearching(false); // Set searching to false when input is cleared
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    React.useEffect(() => {
        const handleOutsideClick = (event) => {
            const searchResults = document.getElementById("SearchResults");
            const searchInput = document.getElementById("SearchInput");
            if (searchResults && !searchResults.contains(event.target) && searchInput && !searchInput.contains(event.target)) {
                setShowDropdown(false);
                props.clearProductSearchProps();
            }
        };
        document.addEventListener('click', handleOutsideClick);

        return () => {
            document.removeEventListener('click', handleOutsideClick);
        };
    }, []);

    React.useEffect(() => {
        if (!props.isSearchingProducts && props.isSearchProducts) {
            setSearching(false); // Set searching to false when the search completes
            if (props.SearchProducts.length === 0) {
                setShowNoResults(true);
            } else {
                setShowNoResults(false);
            }
        }
    }, [props.isSearchingProducts, props.isSearchProducts, props.SearchProducts]);

    return (
        <>
            <div className='search-container'>
                <div className='TopHeader'>
                    <div className='InputBox'>
                        <div className='searchIcon'>
                            <SearchIcon />
                        </div>
                        <input
                            type="search"
                            placeholder={`Search ${localStorage.getItem('storeName') || 'Store'}`}
                            id="SearchInput"
                            value={value}
                            // onFocus={() => setShowDropdown(true)}
                            onChange={(e) =>
                               {  
                                handleInputChange(e.target.value);
                                setShowDropdown(true);
                            }
                                }
                        />
                        {showDropdown && (
                            <ul className='SearchResults' id="SearchResults">
                                {searching ? (
                                    <Loader/>
                                ) : showNoResults ? (
                                    <li>No Search Results</li>
                                ) : props.isSearchProducts && props.SearchProducts && props.SearchProducts.length > 0 ? (
                                    props.SearchProducts.map((product) => (
                                        <li key={product._id} onClick={() => {
                                            localStorage.setItem('productid', product._id);
                                            props.getProductDetails({
                                                userid: localStorage.getItem('userid'),
                                                storeid: localStorage.getItem('productstoreid') || localStorage.getItem('storeid'),
                                                id: product._id
                                            });
                                            setOpen(true);
                                        }}>
                                            <span className='searchresulticon'><SearchIcon /></span>
                                            <span style={{ float: 'left', paddingLeft: '15px', paddingRight: '20px' }}>
                                                {product._source && product._source.image && product._source.image.primary ? (
                                                    <img src={product._source.image.primary} className='searchimage' alt="product" />
                                                ) : (
                                                    <img src={errorproduct} alt="product" className='searchimage' />
                                                )}
                                            </span>
                                            <span style={{
                                                float: 'right', fontWeight: 600,
                                                fontSize: '12px',
                                                lineHeight: '19px',
                                                fontFamily: 'Nunito',
                                                display: "flex",
                                                flexDirection: 'column',
                                                justifyContent: 'center',
                                                alignItems: 'center',
                                                textAlign: 'center',
                                            }}>
                                                {product._source.productname}
                                            </span>
                                        </li>
                                    ))
                                ) : null}
                            </ul>
                        )}
                    </div>
                </div>
            </div>
            <Dialog
                PaperProps={{
                    style: {
                        borderRadius: '10px',
                        backgroundColor: '#ffffff',  
                        width: '100%',
                        height: '80%',
                    }
                }}
                fullScreen={fullScreen}
                maxWidth={'md'}
                scroll={'paper'}
                open={open}
                onClose={handleClose}
                aria-labelledby="responsive-dialog-title"
            >
                <DialogTitle>
                    <span
                        style={{
                            width: '25px', float: 'right',
                            color: '#ffffff',
                            borderRadius: '50%',
                            fontSize: '14px',
                            height: '25px', background: '#BDBDBD', display: 'flex', justifyContent: 'center', alignItems: 'center', textAlign: 'center'
                        }}>
                        <CloseIcon
                            style={{
                                cursor: 'pointer',
                                fontSize: '14px'
                            }} onClick={handleClose} />
                    </span>
                </DialogTitle>
                <DialogContent>
                    <ProductDetails
                        storeoffline={false}
                        props={props}
                    />
                </DialogContent>
            </Dialog>
        </>
    );
};

export default ProductSearch;
