import React from 'react';
import LoginOrSignupModal from './LoginOrSignupModal';
import shopicon from '../../Assets/Images/shopicon.webp';
import logo from '../../Assets/Images/WhiteLogo.svg';
import GoogleMap from './GoogleMap';
import './style.css';
import { useMediaQuery, useTheme } from '@mui/material';

const LocationForm = (props) => {

  const desktop = useMediaQuery(useTheme().breakpoints.up('md'));

 const continueClick = () => {
    if (!localStorage.getItem('location')) { alert('Please Enter a valid location to continue!') }
    else
      props.navigate('/stores')
  }
  // render() {
    return (
      <>
        <div className='root-div'>
          <div className='shopicon-div'>
            {desktop?
            <img 
            src={shopicon} 
            alt="store-icon" 
            className='shopicon' 
            loading='lazy' 
            title='store-icon'
            width='300px'
            height='80px' />
            :
            <img 
            src={logo} 
            alt="store-icon" 
            className='nearshopz-logo' 
            loading='lazy' 
            title='store-icon'
            width='300px'
            height='80px' />
}
          </div>
          <div className='heading'>
            {'Find Stores Near You '}
          </div>
          <div className='googlemap'>
            <GoogleMap {...props} />
          </div>
          <div className='button-div'>
            <button className='continuebutton' onClick={() => { continueClick() }}>
              {'Continue'}
            </button>
          </div>
          <LoginOrSignupModal
            componentkey={'locationform'}
            {...props} />
        </div>
      </>
    )
  // }
}

export default LocationForm