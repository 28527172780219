import React, { useEffect } from "react";
import withClearCache from "../ClearCache";
import ReduxProvider from "./redux";
import { ApolloProvider } from "react-apollo";
import client from "./apollo";
import Main from './Main';
const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}

function MainApp(props) {

  useEffect(() => {
    const loader = document.getElementById("global-loader");
    if (loader) {
      loader.style.display = "none";
    }
  }, []);

    return (
        <div className="App">
            <ReduxProvider>
                <ApolloProvider client={client}>
                    <Main {...props}/>    
                </ApolloProvider>
            </ReduxProvider>
        </div>
    );
}

export default App;
