import { makeStyles } from "@mui/styles";

export const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 8px 44px rgba(0, 0, 0, 0.04)",
    borderRadius: "13px",
    paddingLeft: "24px",
    paddingRight: "24px",
    paddingTop: "18px",
    paddingBottom: "18px",
    //  cursor:'pointer',
  },
  selectedcard: {
    width: "100%",
    boxShadow: "0px 8px 44px rgba(0, 0, 0, 0.04)",
    border: "2.5px solid #69A85C",
    borderRadius: "13px",
    paddingLeft: "24px",
    paddingRight: "24px",
    paddingTop: "18px",
    paddingBottom: "18px",
    background: "#ffffff",
  },
  heading: {
    textAlign: "left",
  },
  orderHeading: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "12px",
    lineHeight: "16px",
    color: "#061303",
    opacity: "0.3",
  },
  orderNumber: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "12px",
    lineHeight: "16px",
    color: "#061303",
  },
  vieworderButton: {
    float: "right",
    textAlign: "right",
    fontSize: "12px",
    color: "#89C74A",
    cursor: "pointer",
  },
  OrderItems: {
    paddingTop: 20,
    paddingBottom: 20,
    textAlign: "left",
    display: "flex",
  },
  storeLogo: {
    width: "39px",
    height: "39px",
    borderRadius: "5px",
  },
  storeDetails: {
    paddingLeft: "11px",
    display: "flex",
    flexDirection: "column",
  },
  storeName: {
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "14px",
    lineHeight: "19px",
    color: " #061303",
  },
  products: {
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px",
    lineHeight: "18px",
    color: "#061303",
    opacity: 0.5,
  },
  divider: {
    height: "0px",
    opacity: 0.5,
    border: "1px solid #EAEEF7",
    width: "100%",
  },
  orderDetails: {
    paddingTop: 17,
    display: "flex",
    flexDirection: "column",
  },
  detailHeading: {
    float: "left",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px",
    lineHeight: "18px",
    color: "#061303",
    opacity: 0.7,
  },
  detail: {
    float: "right",
    fontStyle: "normal",
    fontWeight: 600,
    fontSize: "13px",
    lineHeight: "18px",
    color: "#061303",
    opacity: 0.7,
    textAlign: "right",
  },
  totalHeading: {
    float: "left",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#061303",
    opacity: 0.8,
    paddingBottom: "14px",
    textAlign: "left",
  },
  totalPriceHeading: {
    float: "left",
    fontStyle: "normal",
    fontWeight: 1000,
    fontSize: "14px",
    lineHeight: "18px",
    color: "#061303",
    // opacity: 0.8,
    paddingBottom: "14px",
    textAlign: "left",
  },
  total: {
    float: "right",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "13px",
    lineHeight: "18px",
    color: "#061303",
    opacity: 0.8,
    textAlign: "right",
  },
  totalPrice: {
    float: "right",
    fontStyle: "normal",
    fontWeight: 1000,
    fontSize: "14px",
    lineHeight: "18px",
    color: "#061303",
    // opacity: 0.8,
    textAlign: "right",
  },
  totalPriceDiscount: {
    float: "right",
    fontStyle: "normal",
    fontWeight: 1000,
    fontSize: "14px",
    lineHeight: "18px",
    color: "rgba(45, 147, 251, 1)",
    // opacity: 0.8,
    textAlign: "right",
  },
  orderStatus: {
    paddingTop: "5px",
    "@media(max-width:768px)": {
      paddingTop: "5px",
    },
  },
  ratingDiv: {
    paddingTop: "25px",
    // width: '100%',
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
  },
  accountlistBox: {
    background: " #FFFFFF",
    boxShadow: "0px 8px 44px rgba(0, 0, 0, 0.04)",
    borderTopLeftRadius: "28px",
    borderTopRightRadius: "28px",
    width: "100%",
    height: "auto",
    // padding:'40px'
  },
  userBox: {
    justifyContent: "center",
    textAlign: "center",
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
  },
  cartProductBox: {
    width: "100%",
    padding: "10px",
    borderRadius: "13px",
    backgroundColor: "#FFFFFF",
    boxShadow: "0px 8px 44px rgba(0, 0, 0, 0.04)",
    // border: '1px solid rgba(6, 19, 3, 0.3)',
  },
  cartProductDetails: {
    width: "100%",
    display: "flex",
    textAlign: "left",
  },
  cartImage: {
    maxWidth: "100%",
    maxHeight: "100%",
    width: "68px",
    height: "48px",
  },
  cancelorderdiv: {
    background: "rgba(234, 67, 53, 0.2)",
    borderRadius: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "left",
    color: "#EA4335",
    fontWeight: 600,
    fontSize: "12px",
    // paddingLeft: '15px',
    paddingTop: "15px",
    paddingBottom: "15px",
    cursor: "pointer",
  },
  cancelorder: {
    height: "26px",
    background: "#EA4335",
    borderRadius: "26.5px",
    color: "#fff",
    fontSize: "12px",
    border: "none",
    paddingLeft: "10px",
    paddingRight: "10px",
    cursor: "pointer",
  },
  mobilecancelorder: {
    height: "30px",
    background: "#EA4335",
    borderRadius: "26.5px",
    color: "#fff",
    fontSize: "12px",
    border: "none",
    paddingLeft: "10px",
    paddingRight: "10px",
    cursor: "pointer",
  },
  paynow: {
    height: "26px",
    background: "#89C74A",
    borderRadius: "26.5px",
    color: "#fff",
    fontSize: "12px",
    border: "none",
    paddingLeft: "10px",
    paddingRight: "10px",
    cursor: "pointer",
    width: "100%",
  },
  disabledpaynow: {
    height: "26px",
    background: "rgb(235, 235, 228)",
    borderRadius: "26.5px",
    color: "#fff",
    fontSize: "12px",
    border: "none",
    paddingLeft: "10px",
    paddingRight: "10px",
    cursor: "pointer",
    width: "100%",
  },
  yesbutton: {
    border: "none",
    backgroundColor: "#7FB800",
    color: "#fff",
    cursor: "pointer",
    paddingLeft: "40px",
    paddingRight: "40px",
    borderRadius: "12px",
    paddingTop: "15px",
    paddingBottom: "15px",
  },
  nobutton: {
    border: "none",
    backgroundColor: "rgba(239, 241, 237, 1)",
    color: "#000",
    cursor: "pointer",
    paddingLeft: "40px",
    paddingRight: "40px",
    borderRadius: "12px",
    paddingTop: "15px",
    paddingBottom: "15px",
  },
  arrowButton: {
    background: "#fff",
    border: "1px solid rgba(0, 0, 0, 0.05)",
    width: "30px",
    height: "30px",
    borderRadius: "15px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    cursor: "pointer",
  },
  reportissueheading: {
    color: "#EA4335",
    fontWeight: 700,
    fontSize: "12px",
    lineHeight: "16px",
    cursor: "pointer",
  },
  leftarrow: {
    background: "#EAF6E1",
    width: "30px",
    height: "30px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    borderRadius: "50%",
    cursor: "pointer",
  },
  complaintsList: {
    width: "100%",
    background: "#fff",
    borderRadius: "15px",
    height: "70px",
    marginBottom: "25px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    textAlign: "center",
    color: "#000000",
    fontWeight: 600,
    cursor: "pointer",
  },
  previousComplaintsDiv: {
    display: "flex",
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: "20px",
    paddingRight: "20px",
    cursor: "pointer",
  },
  previousComplaintsHeading: {
    display: "flex",
    width: "100%",
    flexDirection: "column",
  },
  previousComplaintsMainmessage: {
    color: "#000000",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "33px",
  },
  previousComplaintsOrderNumber: {
    color: "#000000",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "33px",
    paddingBottom: "20px",
    opacity: 0.5,
  },
  previousComplaintsArrow: {
    color: "#000000",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "16px",
    lineHeight: "33px",
    float: "right",
    textAlign: "right",
    display: "flex",
    justifyContent: "center",
  },
  previousComplaints: {
    color: "#000000",
    fontStyle: "normal",
    fontWeight: "bold",
    fontSize: "19px",
    lineHeight: "33px",
    paddingBottom: "10px",
    
  },
  complaintDetailsDiv: {
    background: "#fff",
    borderTopLeftRadius: "24px",
    borderTopRightRadius: "24px",
    height: "100%",
    padding: "35px",
    color: "#000000",
    marginTop: "90px",
  },
  leftAlignGrid: {
    display: "flex",
    textAlign: "left",
    justifyContent: "left",
    fontSize: "16px",
    fontWeight: 600,
  },
  rightAlignGrid: {
    display: "flex",
    textAlign: "right",
    justifyContent: "right",
    fontSize: "16px",
    fontWeight: 600,
  },
  headingGrid: {
    paddingTop: "25px",
    paddingBottom: "20px",
    fontWeight: 600,
    fontSize: "18px",
  },
  messageGrid: {
    color: "rgba(0,0,0,0.5)",
    fontSize: "18px",
  },
  usercomplaintdiv: {
    background: "rgba(137, 199, 74, 1)",
    width: "fit-content",
    padding: "10px",
    borderRadius: "15px",
    color: "#fff",
    fontSize: "14px",
    fontWeight: 600,
  },
  usercomplaintdisableddiv: {
    background: "#E5E5E5",
    width: "fit-content",
    padding: "10px",
    borderRadius: "15px",
    color: "#000",
    fontSize: "14px",
    fontWeight: 600,
  },
  itemlistdiv: {
    background: "rgba(0,0,0,0.2)",
    width: "fit-content",
    padding: "10px",
    borderRadius: "15px",
    color: "#000",
    fontSize: "14px",
    fontWeight: 600,
    textAlign: "right",
  },
  complaintImage: {
    maxHeight: "100%",
    maxWidth: "100%",
    width: "100px",
    height: "100px",
  },
  submitbutton: {
    height: "40px",
    background: "#89C74A",
    borderRadius: "26.5px",
    color: "#fff",
    fontSize: "16px",
    border: "none",
    paddingLeft: "10px",
    paddingRight: "10px",
    cursor: "pointer",
    width: "64%",
    "@media(max-width: 768px)": {
      height: "35px",
      width: "60%",
    },
    "@media(max-width: 320px)": {
      height: "35px",
      width: "90%",
    },
  },
  modalHeading: {
    fontWeight: 1000,
    fontSize: "18px",
    lineHeight: "25px",
    textAlign: "center",
    color: "#000000",
    fontFamily: "Nunito",
    paddingLeft: "10px",
    "@media(max-width: 1024px)": {
      paddingLeft: "20px",
    },
    "@media(max-width: 320px)": {
      paddingLeft: "5px",
    },
  },
  disabledbutton: {
    height: "46px",
    background: "#E5E5E5",
    borderRadius: "26.5px",
    color: "#000",
    fontSize: "16px",
    border: "none",
    paddingLeft: "10px",
    paddingRight: "10px",
    cursor: "pointer",
    width: "50%",
  },
}));
